import React from "react";
import { Link } from "react-router-dom";

const PaginatedTemplate = ({ currentTemplates, setId, setModal }) => {
  const values = (e, id) => {
    setId(id);
    setModal(true);
  };
  return (
    <>
      {currentTemplates?.map((template, key) => {
        return (
          <tr key={key}>
            <td>{template.name}</td>
            <td>
              <Link
                to={`/admin/edit-template/${template.id}`}
                className="btn btn-sm btn-secondary"
              >
                <i className="fa fa-edit" aria-hidden="true"></i>
              </Link>
              <button
                className="btn btn-sm btn-secondary"
                onClick={(e) => values(e, template.id)}
              >
                <i className="fa fa-times" aria-hidden="true"></i>
              </button>
            </td>
          </tr>
        );
      })}
    </>
  );
};
export default PaginatedTemplate;

import React from "react";
import { Link } from "react-router-dom";

const Paginatedsubcategories = ({
  currentSubcategories,
  setSubcategories,
  setId,
  setModal,
}) => {
  const setValues = (e, idd) => {
    e.preventDefault();
    setModal(true);
    setId(idd);
  };
  return (
    <>
      {currentSubcategories?.map((subcategory, key) => {
        return (
          <tr key={key}>
            <td>{subcategory.name}</td>
            {/* <td>{subcategory.category.name}</td> */}
            <td>
              <ul className="action-list">
                <li>
                  <Link
                    to={`/admin/edit-sub-categories/${subcategory.id}`}
                    className="text-white"
                  >
                    {/* <button
                  data-tip="edit"
                  title="edit"
                  className="text-primary"
                > */}
                    <i className="fa fa-edit"></i>
                    {/* </button> */}
                  </Link>
                </li>
                <li>
                  <h6
                    data-tip="delete"
                    title="delete"
                    onClick={(e) => setValues(e, subcategory.id)}
                  >
                    <i className="fa fa-trash"></i>
                  </h6>
                </li>
              </ul>
            </td>
          </tr>
        );
      })}
    </>
  );
};

export default Paginatedsubcategories;

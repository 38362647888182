import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import axios from "axios";
import { notify } from "../toastify/TostifyMessages";
import { Col, Modal, ModalBody, ModalHeader, Row } from "reactstrap";
// import { DeleteAlert } from "../deletes/DeleteAlert";
// import { deleteModal, modalFalse } from "../deletes/delet";
const ViewDomain = () => {
  const [domains, setDomain] = useState([]);
  const [searchKey, setSearchKey] = useState("");
  const [modal, setModal] = useState(false);
  const [ids, setIds] = useState(0);

  useEffect(() => {
    getDomains();
    if (searchKey !== "") {
      searchDomains();
    }
  }, [searchKey]);

  const getDomains = () => {
    axios.get(`/api/get-domains`).then((res) => {
      if (res.data.status === 200) {
        setDomain(res.data.domains);
      }
    });
  };
  const searchDomains = () => {
    axios.get(`/api/search-domain/${searchKey}`).then((res) => {
      if (res.data.status === 200) {
        setDomain(res.data.domain);
      }
    });
  };

  const deleteConfirmation = (e, ids) => {
    e.preventDefault();
    setModal(true);
    setIds(ids);

    console.log("modal", modal);
    console.log("id", ids);
    // deleteModal(true);
    // setModal(modalFalse());
    // return <DeleteAlert />;
  };
  const deleteDomain = (e) => {
    e.preventDefault();
    let id = ids;

    axios.delete(`/api/delete-domain/${id}`).then((res) => {
      if (res.data.status === 200) {
        setModal(false);
        notify("success", res.data.message);
      } else if (res.data.status === 400) {
        notify("warning", res.data.message);
      }
    });
    getDomains();
  };

  const reloadPage = () => {
    setSearchKey("");
    getDomains();
  };
  return (
    <>
      <div className="container-fluid my-4">
        <div className="row">
          <div className="col-md-offset-1 col-md-12">
            <div className="panel">
              <div className="panel-heading">
                <div className="row">
                  <div className="col col-sm-12 col-xs-12">
                    <h4 className="title">
                      Domains <span>List</span>
                      <div className="float-end">
                        <div className="btn_group">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Search"
                            name="search"
                            onChange={(e) => setSearchKey(e.target.value)}
                            value={searchKey}
                          />
                          <button
                            className="btn btn-default"
                            data-tip="Reload"
                            onClick={reloadPage}
                          >
                            <i className="fa fa-sync-alt"></i>
                          </button>
                        </div>
                      </div>
                    </h4>
                  </div>
                  {/* <div className="col-sm-9 col-xs-12 text-right">
                    <div className="btn_group">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Search"
                        name="search"
                        onChange={(e) => setSearchKey(e.target.value)}
                        value={searchKey}
                      />
                      <button
                        className="btn btn-default"
                        data-tip="Reload"
                        onClick={reloadPage}
                      >
                        <i className="fa fa-sync-alt"></i>
                      </button>

                      <button
                        className="btn btn-default"
                        data-tip="Excel"
                        onClick={handleExport}
                      >
                        <i className="fas fa-file-excel"></i>
                      </button>
                    </div>
                  </div> */}
                </div>
              </div>
              <div className="panel-body table-responsive">
                <table className="table">
                  <thead>
                    <tr>
                      <th>Domain Email</th>
                      <th>Password</th>
                      <th>Server</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {domains?.map((domain, key) => {
                      return (
                        <tr key={key}>
                          <td>{domain.name}</td>
                          <td>{domain.password}</td>
                          <td>{domain.servers.host}</td>

                          <td>
                            <ul className="action-list">
                              <li>
                                <Link
                                  to={`/admin/edit-domain/${domain.id}`}
                                  className="text-white"
                                >
                                  {/* <button
                                    data-tip="edit"
                                    title="edit"
                                    className="text-primary"
                                  > */}
                                  <i className="fa fa-edit"></i>
                                  {/* </button> */}
                                </Link>
                              </li>
                              <li>
                                <h6
                                  data-tip="delete"
                                  title="delete"
                                  onClick={(e) =>
                                    deleteConfirmation(e, domain.id)
                                  }
                                >
                                  <i className="fa fa-trash"></i>
                                </h6>
                              </li>
                            </ul>
                          </td>
                        </tr>
                      );
                    })}
                    {/* </tr> */}
                    {/* <tr>
                      <td>2</td>
                      <td>Taylor Reyes</td>
                      <td>22</td>
                      <td>UI/UX Developer</td>
                      <td>Baileux</td>
                      <td>
                        <ul className="action-list">
                          <li>
                            <a href="#" data-tip="edit">
                              <i className="fa fa-edit"></i>
                            </a>
                          </li>
                          <li>
                            <a href="#" data-tip="delete">
                              <i className="fa fa-trash"></i>
                            </a>
                          </li>
                        </ul>
                      </td>
                    </tr>
                    <tr>
                      <td>3</td>
                      <td>Justin Block</td>
                      <td>26</td>
                      <td>Frontend Developer</td>
                      <td>Overland Park</td>
                      <td>
                        <ul className="action-list">
                          <li>
                            <a href="#" data-tip="edit">
                              <i className="fa fa-edit"></i>
                            </a>
                          </li>
                          <li>
                            <a href="#" data-tip="delete">
                              <i className="fa fa-trash"></i>
                            </a>
                          </li>
                        </ul>
                      </td>
                    </tr>
                    <tr>
                      <td>4</td>
                      <td>Sean Guzman</td>
                      <td>26</td>
                      <td>Web Designer</td>
                      <td>Gloucester</td>
                      <td>
                        <ul className="action-list">
                          <li>
                            <i className="fa fa-edit"></i>
                          </li>
                          <li>
                            <i className="fa fa-trash"></i>
                          </li>
                        </ul>
                      </td>
                    </tr>
                    <tr>
                      <td>5</td>
                      <td>Keith Carter</td>
                      <td>20</td>
                      <td>Graphic Designer</td>
                      <td>Oud-Turnhout</td>
                      <td>
                        <ul className="action-list">
                          <li>
                            <a href="#" data-tip="edit">
                              <i className="fa fa-edit"></i>
                            </a>
                          </li>
                          <li>
                            <a href="#" data-tip="delete">
                              <i className="fa fa-trash"></i>
                            </a>
                          </li>
                        </ul>
                      </td>
                    </tr> */}
                  </tbody>
                </table>
              </div>
              {/* <div className="panel-footer">
                <div className="row">
                  <div className="col col-sm-6 col-xs-6">
                    showing <b>5</b> out of <b>25</b> entries
                  </div>
                  <div className="col-sm-6 col-xs-6">
                    <ul className="pagination hidden-xs pull-right">
                      <li>
                        <a href="#">&gt;</a>
                      </li>
                      <li className="active">
                        <a href="#">1</a>
                      </li>
                      <li>
                        <a href="#">2</a>
                      </li>
                      <li>
                        <a href="#">3</a>
                      </li>
                      <li>
                        <a href="#">4</a>
                      </li>
                      <li>
                        <a href="#">5</a>
                      </li>
                      <li>
                        <a href="#">&gt;</a>
                      </li>
                    </ul>
                    <ul className="pagination visible-xs pull-right">
                      <li>
                        <a href="#">&gt;</a>
                      </li>
                      <li>
                        <a href="#">&gt;</a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div> */}
            </div>
          </div>
        </div>
        <Modal size="lg" isOpen={modal} toggle={() => setModal(!modal)}>
          <ModalHeader>Confirmation</ModalHeader>
          <ModalBody>
            <div className="row text-center">
              <div className="col-md-12">
                <p>Are You sure to delete?</p>
              </div>
              <div className="col-md-6 text-center">
                <button
                  onClick={(e) => deleteDomain(e)}
                  className="btn btn-sm btn-danger mt-2"
                >
                  Yes
                </button>
              </div>
              <div className="col-md-6">
                <button
                  className="btn btn-sm btn-secondary"
                  onClick={(e) => setModal(false)}
                >
                  Cancel
                </button>
              </div>
            </div>
          </ModalBody>
        </Modal>
        <ToastContainer />
      </div>
    </>
  );
};

export default ViewDomain;

import React, { useState, useEffect } from "react";
import { ToastContainer } from "react-toastify";
import axios from "axios";
import { Col, Modal, ModalBody, ModalHeader, Row } from "reactstrap";
import { notify } from "../toastify/TostifyMessages";
import Paginatedsubcategories from "./Paginatedsubcategories";
import Pagination from "../pagination/Pagination";

const ViewSubcategory = () => {
  const [searchKey, setSearchKey] = useState("");
  const [subCategories, setSubcategories] = useState([]);
  const [modal, setModal] = useState(false);
  const [id, setId] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [subcategoriesPerPage, setSubcategoriesPerPage] = useState(10);

  useEffect(() => {
    viewSubcategory();
    if (searchKey !== "") {
      axios.get(`/api/search-subcategories/${searchKey}`).then((res) => {
        if (res.data.status === 200) {
          setSubcategories(res.data.subcategories);
          // console.log("leads", leads);
        }
      });
    }
  }, [searchKey]);

  const viewSubcategory = () => {
    axios.get(`/api/view-subcategories`).then((res) => {
      if (res.data.status === 200) {
        setSubcategories(res.data.subCategoryData);
        // console.log("leads", leads);
      }
    });
  };
  const deleteSubcategory = (e) => {
    e.preventDefault();
    // const thisClicked = e.currentTarget;
    // thisClicked.innerText = "Deleting";

    const ids = id;
    setModal(false);
    axios.delete(`/api/delete-subcategory/${ids}`).then((res) => {
      if (res.data.status === 200) {
        // swal("Success", res.data.message, "success");
        // thisClicked.closest("tr").remove();
        notify("success", res.data.message);
      } else if (res.data.status === 400) {
        // swal("Warning", res.data.message, "warning");
        // thisClicked.innerText = "Delete";
        notify("warning", res.data.message);
      }
    });
    viewSubcategory();
  };
  // const setValues = (e, idd) => {
  //   e.preventDefault();
  //   setModal(true);
  //   setId(idd);
  // };
  const cancelDelete = (e) => {
    setModal(false);
  };
  const reloadPage = (e) => {
    e.preventDefault();
    setSearchKey("");
    viewSubcategory();
  };

  const lastSubcategoryIndex = currentPage * subcategoriesPerPage;
  const firstSubcategoryIndex = lastSubcategoryIndex - subcategoriesPerPage;
  const currentSubcategories = subCategories.slice(
    firstSubcategoryIndex,
    lastSubcategoryIndex
  );

  console.log("current subcategories", currentSubcategories);
  return (
    <>
      <div className="container-fluid my-4">
        <div className="row">
          <div className="col-md-offset-1 col-md-12">
            <div className="panel">
              <div className="panel-heading">
                <div className="row">
                  <div className="col col-sm-12 col-xs-12">
                    <h4 className="title">
                      Subcategory <span>List</span>
                      <div className="float-end">
                        <div className="btn_group">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Search"
                            name="search"
                            onChange={(e) => setSearchKey(e.target.value)}
                            value={searchKey}
                          />
                          <button
                            className="btn btn-default"
                            data-tip="Reload"
                            onClick={reloadPage}
                          >
                            <i className="fa fa-sync-alt"></i>
                          </button>
                        </div>
                      </div>
                    </h4>
                  </div>
                  {/* <div className="col-sm-9 col-xs-12 text-right">
                    <div className="btn_group">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Search"
                        name="search"
                        onChange={(e) => setSearchKey(e.target.value)}
                        value={searchKey}
                      />
                      <button
                        className="btn btn-default"
                        data-tip="Reload"
                        onClick={reloadPage}
                      >
                        <i className="fa fa-sync-alt"></i>
                      </button>

                      <button
                        className="btn btn-default"
                        data-tip="Excel"
                        onClick={handleExport}
                      >
                        <i className="fas fa-file-excel"></i>
                      </button>
                    </div>
                  </div> */}
                </div>
              </div>
              <div className="panel-body table-responsive">
                <table className="table">
                  <thead>
                    <tr>
                      <th>Subcategory Name</th>
                      {/* <th>Category</th> */}
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    <Paginatedsubcategories
                      currentSubcategories={currentSubcategories}
                      setSubcategories={setSubcategories}
                      setId={setId}
                      setModal={setModal}
                    />
                  </tbody>
                </table>
              </div>
              <div className="panel-footer">
                <Pagination
                  totalRecord={subCategories.length}
                  recordPerPage={subcategoriesPerPage}
                  setCurrentPage={setCurrentPage}
                  recordCount={currentSubcategories.length}
                />
                {/* <div className="row">
                  <div className="col col-sm-6 col-xs-6">
                    showing <b>5</b> out of <b>25</b> entries
                  </div>
                  <div className="col-sm-6 col-xs-6">
                    <ul className="pagination hidden-xs pull-right">
                      <li>
                        <a href="#">&gt;</a>
                      </li>
                      <li className="active">
                        <a href="#">1</a>
                      </li>
                      <li>
                        <a href="#">2</a>
                      </li>
                      <li>
                        <a href="#">3</a>
                      </li>
                      <li>
                        <a href="#">4</a>
                      </li>
                      <li>
                        <a href="#">5</a>
                      </li>
                      <li>
                        <a href="#">&gt;</a>
                      </li>
                    </ul>
                    <ul className="pagination visible-xs pull-right">
                      <li>
                        <a href="#">&gt;</a>
                      </li>
                      <li>
                        <a href="#">&gt;</a>
                      </li>
                    </ul>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        </div>
        <Modal size="lg" isOpen={modal} toggle={() => setModal(!modal)}>
          <ModalHeader>Confirmation</ModalHeader>
          <ModalBody>
            <Row md={12}>
              <Col>
                <div>
                  <p>Are you sure to delet it...</p>
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                <button
                  data-tip="confirm"
                  onClick={(e) => deleteSubcategory(e)}
                  className="btn-sm btn-secondary mt-2"
                >
                  Yes
                </button>
              </Col>
              <Col>
                <button
                  data-tip="cancel"
                  onClick={(e) => cancelDelete(e)}
                  className="btn-sm btn-info mt-2"
                >
                  No
                </button>
              </Col>
            </Row>
          </ModalBody>
        </Modal>
        <ToastContainer />
      </div>
    </>
  );
};

export default ViewSubcategory;

import Home from "./frontend/Home";
import ViewLeads from "./backend/ViewLeads";
import { Route, Routes } from "react-router-dom";
import Login from "./frontend/Login";
import MasterLayout from "./backend/layout/MasterLayout";
import Dashboard from "./backend/Dashboard";
import ManageLeads from "./backend/ManageLeads";
import AddCategory from "./backend/categories/AddCategories";
import ViewCategories from "./backend/categories/ViewCategories";
import AddSubcategory from "./backend/subcategories/AddSubcategory";
import ViewSubcategory from "./backend/subcategories/ViewSubcategory";
import axios from "axios";
import AddLeads from "./backend/leads/AddLeads";
import EditLeads from "./backend/leads/EditLeads";
import EditCategories from "./backend/categories/EditCategories";
import EditSubCategories from "./backend/subcategories/EditSubCategories";
import AddContent from "./backend/emails/AddContent";
import ViewContent from "./backend/emails/ViewContent";
import EditContent from "./backend/emails/EditContent";
import AddServer from "./backend/smtp_servers/AddServer";
import ViewServers from "./backend/smtp_servers/ViewServers";
import EditServer from "./backend/smtp_servers/EditServer";
import AddEmail from "./backend/from_email/AddEMail";
import ViewEmail from "./backend/from_email/ViewEmail";
import EditEmail from "./backend/from_email/EditEmail";
import AddDomain from "./backend/domains/AddDomain";
import ViewDomain from "./backend/domains/ViewDomain";
import EditDomain from "./backend/domains/EditDomain";
import SendCompagion from "./backend/compagions/SendCompagion";
import AddTemplate from "./backend/templates/AddTemplate";
import ViewTemplate from "./backend/templates/ViewTemplate";
import EditTemplate from "./backend/templates/EditTemplate";
import SendCompagions2 from "./backend/compagions/SendCompagions2";
import ViewCompagions from "./backend/compagions/ViewCompagions";
import TableTest from "./backend/testComponents/TableTest";
import Successfull from "./backend/compagions/Successfull";
import Cancelled from "./backend/compagions/Cancelled";

// axios.defaults.baseURL = "http://127.0.0.1:8000/";
axios.defaults.baseURL = "https://api.livechatsupport.me/";
axios.defaults.headers.post["Content-Type"] = "application/json";
axios.defaults.headers.post["Accept"] = "application/json";
axios.defaults.withCredentials = true;

axios.interceptors.request.use(function (config) {
  const token = localStorage.getItem("auth_token");
  config.headers.Authorization = token ? `Bearer ${token}` : "";
  return config;
});
function App() {
  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<Home />}></Route>
        <Route path="/login" element={<Login />}></Route>
        {/* <Route path="/admin" element={<Protected Component={MasterLayout} />}> */}
        <Route path="/admin" element={<MasterLayout />}>
          <Route path="/admin/dashboard" element={<Dashboard />}></Route>
          <Route path="/admin/view" element={<ViewLeads />}></Route>
          <Route path="/admin/manage" element={<ManageLeads />}></Route>
          <Route path="/admin/add-category" element={<AddCategory />}></Route>
          <Route
            path="/admin/view-categories"
            element={<ViewCategories />}
          ></Route>
          <Route
            path="/admin/add-subcategory"
            element={<AddSubcategory />}
          ></Route>
          <Route
            path="/admin/view-subcategories"
            element={<ViewSubcategory />}
          ></Route>
          <Route path="/admin/add-leads" element={<AddLeads />}></Route>
          <Route path="/admin/edit-leads/:id" element={<EditLeads />}></Route>
          <Route
            path="/admin/edit-categories/:id"
            element={<EditCategories />}
          ></Route>
          <Route
            path="/admin/edit-sub-categories/:id"
            element={<EditSubCategories />}
          ></Route>
          <Route
            path="/admin/add-email-content"
            element={<AddContent />}
          ></Route>
          <Route path="/admin/email-content" element={<ViewContent />}></Route>
          <Route
            path="/admin/edit-email-content/:id"
            element={<EditContent />}
          ></Route>
          <Route path="/admin/add-server" element={<AddServer />}></Route>
          <Route path="/admin/view-server" element={<ViewServers />}></Route>
          <Route path="/admin/edit-server/:id" element={<EditServer />}></Route>
          <Route path="/admin/add-email" element={<AddEmail />}></Route>
          <Route path="/admin/view-email" element={<ViewEmail />}></Route>
          <Route path="/admin/edit-email/:id" element={<EditEmail />}></Route>
          <Route path="/admin/add-domain" element={<AddDomain />}></Route>
          <Route path="/admin/view-domain" element={<ViewDomain />}></Route>
          <Route path="/admin/edit-domain/:id" element={<EditDomain />}></Route>
          <Route
            path="/admin/add-compagion"
            element={<SendCompagion />}
          ></Route>
          <Route path="/admin/add-template" element={<AddTemplate />}></Route>
          <Route path="/admin/view-template" element={<ViewTemplate />}></Route>
          <Route
            path="/admin/edit-template/:id"
            element={<EditTemplate />}
          ></Route>
          <Route
            path="/admin/send-compagion/:id"
            element={<SendCompagions2 />}
          ></Route>
          <Route
            path="/admin/view-compagions"
            element={<ViewCompagions />}
          ></Route>
          <Route path="/admin/test" element={<TableTest />}></Route>
          <Route
            path="/admin/successfull-compagions"
            element={<Successfull />}
          ></Route>
          <Route
            path="/admin/cancelled-compagions"
            element={<Cancelled />}
          ></Route>
          <Route
            path="/admin/cancelled-compagions"
            element={<Cancelled />}
          ></Route>
        </Route>
      </Routes>
    </div>
  );
}

export default App;

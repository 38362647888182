import React, { useState } from "react";
import { Link } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import axios from "axios";
import { notify } from "../toastify/TostifyMessages";
const AddCategory = () => {
  const [category, setCategory] = useState({
    name: "",
    default: "",
    errors_list: [],
  });

  const handleInput = (e) => {
    setCategory({
      ...category,
      [e.target.name]: [e.target.value],
    });
  };

  const submitForm = (e) => {
    e.preventDefault();

    const form = new FormData();

    form.append("name", category.name);
    form.append("default_category", category.default);

    axios.post(`/api/add-category`, form).then((res) => {
      if (res.data.status === 200) {
        console.log("success", res.data.message);
        notify("success", res.data.message);
      } else if (res.data.status === 403) {
        console.log("errors", res.data.errors);
        setCategory({
          ...category,
          errors_list: res.data.errors,
        });
        notify("error", res.data.message);
      }
    });
  };
  return (
    <>
      <div className="container mt-4">
        <div className="card">
          <div className="card-header">
            <h4>
              Add Category
              <Link
                to="/admin/view-categories"
                className="btn btn-primary btn-sm float-end"
              >
                <i className="fa fa-eye" aria-hidden="true"></i>
                View Categories
              </Link>
            </h4>
          </div>

          <div className="card-body">
            <form onSubmit={submitForm}>
              <div className="row mb-4">
                <div className="col-sm-6 col-offset-1">
                  <div className="form-outline">
                    <label className="form-label" htmlFor="form3Example1">
                      Category Name
                    </label>
                    <input
                      type="text"
                      id="form3Example1"
                      className="form-control"
                      name="name"
                      value={category.name}
                      onChange={handleInput}
                    />
                    <span className="text-danger">
                      {category.errors_list.name}
                    </span>
                  </div>
                </div>
                <div className="col-sm-6 col-offset-1">
                  <div className="form-outline">
                    <label className="form-label" htmlFor="default">
                      Default Category
                    </label>
                    <select
                      id="default"
                      className="form-control"
                      name="default"
                      value={category.default}
                      onChange={handleInput}
                    >
                      <option value="">select one option</option>
                      <option value="1">Yes</option>
                      <option value="0">No</option>
                    </select>
                    <span className="text-danger">
                      {category.errors_list.default}
                    </span>
                  </div>
                </div>
              </div>

              <button type="submit" className="btn btn-primary btn-block mb-4">
                Add
              </button>
            </form>
          </div>
        </div>
      </div>
      <ToastContainer />
    </>
  );
};

export default AddCategory;

import React, { useState } from "react";
import { Link } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { notify } from "../toastify/TostifyMessages";
import axios from "axios";
const AddServer = () => {
  const [server, setServer] = useState({
    mailer: "",
    host: "",
    port: "",
    encryption: "",
    errors_list: [],
  });

  const handleInput = (e) => {
    setServer({
      ...server,
      [e.target.name]: [e.target.value],
    });
  };
  const submitForm = (e) => {
    e.preventDefault();

    let form = new FormData();

    form.append("mailer", server.mailer);
    form.append("host", server.host);
    form.append("port", server.port);
    form.append("encryption", server.encryption);

    axios.post(`/api/add-server`, form).then((res) => {
      if (res.data.status === 200) {
        console.log("success", res.data.message);
        notify("success", res.data.message);
      } else if (res.data.status === 403) {
        console.log("errors", res.data.errors);
        setServer({
          ...server,
          errors_list: res.data.errors,
        });
        notify("error", res.data.message);
      }
    });
  };
  return (
    <>
      <div className="container mt-4">
        <div className="card">
          <div className="card-header">
            <h4>
              Add Email server
              <Link
                to="/admin/view-server"
                className="btn btn-primary btn-sm float-end"
              >
                <i className="fa fa-eye" aria-hidden="true"></i>
                View Email servers
              </Link>
            </h4>
          </div>

          <div className="card-body">
            <form onSubmit={submitForm}>
              <div className="row mb-4">
                <div className="col-sm-6 col-offset-1">
                  <div className="form-outline">
                    <label className="form-label" htmlFor="mailer">
                      MAIL MAILER
                    </label>
                    <input
                      type="text"
                      id="mailer"
                      className="form-control"
                      name="mailer"
                      value={server.mailer}
                      onChange={handleInput}
                    />
                    <span className="text-danger">
                      {server.errors_list.mailer}
                    </span>
                  </div>
                </div>
                <div className="col-sm-6 col-offset-1">
                  <div className="form-outline">
                    <label className="form-label" htmlFor="host">
                      MAIL HOST
                    </label>
                    <input
                      type="text"
                      id="host"
                      className="form-control"
                      name="host"
                      value={server.host}
                      onChange={handleInput}
                    />
                    <span className="text-danger">
                      {server.errors_list.host}
                    </span>
                  </div>
                </div>
              </div>
              <div className="row mb-4">
                <div className="col-sm-6 col-offset-1">
                  <div className="form-outline">
                    <label className="form-label" htmlFor="port">
                      MAIL PORT
                    </label>
                    <input
                      id="port"
                      className="form-control"
                      type="number"
                      name="port"
                      value={server.port}
                      onChange={handleInput}
                    />
                    <span className="text-danger">
                      {server.errors_list.port}
                    </span>
                  </div>
                </div>
                <div className="col-sm-6 col-offset-1">
                  <div className="form-outline">
                    <label className="form-label" htmlFor="encryption">
                      MAIL ENCRYPTION
                    </label>
                    <input
                      id="encryption"
                      className="form-control"
                      type="text"
                      name="encryption"
                      value={server.encryption}
                      onChange={handleInput}
                    />
                    <span className="text-danger">
                      {server.errors_list.encryption}
                    </span>
                  </div>
                </div>
              </div>
              <button type="submit" className="btn btn-primary btn-block mb-4">
                Add
              </button>
            </form>
          </div>
        </div>
      </div>
      <ToastContainer />
    </>
  );
};

export default AddServer;

import React, { useEffect, useState } from "react";
import { ToastContainer } from "react-toastify";
import { Link } from "react-router-dom";
import axios from "axios";
import { notify } from "../toastify/TostifyMessages";

const AddSubcategory = () => {
  const [subCategory, setSubcategory] = useState({
    name: "",
    category: "",
    errors_list: [],
  });

  const [categoryList, setCategoryList] = useState([]);

  useEffect(() => {
    axios.get("/api/view-categories").then((res) => {
      setCategoryList(res.data.categoryData);
    });
  }, []);

  const handleInput = (e) => {
    setSubcategory({
      ...subCategory,
      [e.target.name]: [e.target.value],
    });
  };

  const submitForm = (e) => {
    e.preventDefault();
    const form = new FormData();

    form.append("name", subCategory.name);
    form.append("category_id", subCategory.category);

    axios.post(`/api/add-subcategory`, form).then((res) => {
      if (res.data.status === 200) {
        console.log("success", res.data.message);
        notify("success", res.data.message);
      } else if (res.data.status === 403) {
        console.log("errors", res.data.errors);
        setSubcategory({
          ...subCategory,
          errors_list: res.data.errors,
        });
        notify("error", res.data.message);
      }
    });
  };
  return (
    <>
      <div className="container mt-4">
        <div className="card">
          <div className="card-header">
            <h4>
              Add Sub Category
              <Link
                to="/admin/view-subcategories"
                className="btn btn-primary btn-sm float-end"
              >
                <i className="fa fa-eye" aria-hidden="true"></i>
                View Sub Categories
              </Link>
            </h4>
          </div>

          <div className="card-body">
            <form onSubmit={submitForm}>
              <div className="row mb-4">
                <div className="col-sm-6">
                  <div className="form-outline">
                    <label className="form-label" htmlFor="form3Example1">
                      Sub Category Name
                    </label>
                    <input
                      type="text"
                      id="form3Example1"
                      className="form-control"
                      name="name"
                      value={subCategory.name}
                      onChange={handleInput}
                    />
                    <span className="text-danger"></span>
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="form-outline">
                    <label className="form-label" htmlFor="form3Example1">
                      Category
                    </label>
                    <select
                      className="form-control"
                      name="category"
                      onChange={handleInput}
                      value={subCategory.category}
                    >
                      <option value="">select category</option>
                      {categoryList.map((item) => {
                        return (
                          <option value={item.id} key={item.id}>
                            {item.name}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </div>
              </div>

              <button type="submit" className="btn btn-primary btn-block mb-4">
                Add
              </button>
            </form>
          </div>
        </div>
      </div>
      <ToastContainer />
    </>
  );
};

export default AddSubcategory;

import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { ToastContainer } from "react-toastify";

import JoditEditor from "jodit-react";
import { notify } from "../toastify/TostifyMessages";
import axios from "axios";
const EditTemplate = () => {
  const [template, setTemplate] = useState([]);
  const [contents, setContents] = useState("");
  const params = useParams();
  const id = params["id"];

  useEffect(() => {
    axios.get(`/api/edit-template/${id}`).then((res) => {
      if (res.data.status === 200) {
        console.log("Response Data", res.data.htmlData);
        setTemplate(res.data.template);
      } else if (res.data.status === 404) {
        console.warn("else part");
        // navigate("/admin");
      }
    });
  }, [id]);

  const handleInput = (e) => {
    setTemplate({
      ...template,
      [e.target.name]: [e.target.value],
    });
  };

  const exportHtml = (e) => {
    e.preventDefault();

    const form = new FormData();
    form.append("name", template.name);
    form.append("html_code", contents);
    axios.post(`/api/update-template/${id}`, form).then((res) => {
      if (res.data.status === 200) {
        // console.log("success", res.data.message);
        notify("success", res.data.message);
      } else if (res.data.status === 400) {
        // console.log("errors", res.data.errors);

        notify("error", res.data.message);
      }
    });
  };

  return (
    <>
      <div className="container mt-4">
        <div className="card">
          <div className="card-header">
            <h4>
              Edit Email Template
              <Link
                to="/admin/view-template"
                className="btn btn-primary btn-sm float-end"
              >
                <i className="fa fa-eye" aria-hidden="true"></i>
                Email Template
              </Link>
            </h4>
          </div>

          <div className="card-body">
            <div className="row mb-4">
              <div className="col-sm-6 col-offset-1">
                <div className="form-outline">
                  <label className="form-label" htmlFor="name">
                    Template Name
                  </label>
                  <input
                    type="text"
                    id="name"
                    className="form-control"
                    name="name"
                    value={template.name}
                    onChange={handleInput}
                  />
                  <span className="text-danger">
                    {/* {template.errors_list.name} */}
                  </span>
                </div>
              </div>
            </div>
            {/* <EmailEditor ref={emailEditorRef} onLoad={() => onLoad} /> */}

            <JoditEditor
              value={template.html_code}
              onChange={(newContent) => setContents(newContent)}
            />
            <button className="btn btn-sm btn-primary" onClick={exportHtml}>
              Update Template
            </button>
          </div>
        </div>
      </div>
      <ToastContainer />
    </>
  );
};

export default EditTemplate;

import React, { useState } from "react";
// import React, { useRef, useState } from "react";
// import EmailEditor from "react-email-editor";
import { ToastContainer } from "react-toastify";
import { Link } from "react-router-dom";
import axios from "axios";
import { notify } from "../toastify/TostifyMessages";
import JoditEditor from "jodit-react";
const AddTemplate = () => {
  // const emailEditorRef = useRef(null);
  const [contents, setContents] = useState("");
  const [template, setTemplate] = useState({
    name: "",
    errors_list: [],
  });

  const handleInput = (e) => {
    setTemplate({
      ...template,
      [e.target.name]: [e.target.value],
    });
  };
  const exportHtml = () => {
    // emailEditorRef.current.editor.exportHtml((data) => {
    //   const { design, html } = data;
    //   console.log("exportHtml", html);
    let form = new FormData();

    form.append("name", template.name[0]);
    form.append("html_code", contents);
    // form.append("html_code", html);
    console.log("exportHtml fun", template.name[0]);

    axios.post(`/api/add-template`, form).then((res) => {
      if (res.data.status === 200) {
        console.log("success", res.data.message);
        notify("success", res.data.message);
      } else if (res.data.status === 403) {
        console.log("errors", res.data.errors);
        setTemplate({
          ...template,
          errors_list: res.data.errors,
        });
        notify("error", res.data.message);
      }
    });
    // });
  };
  return (
    <>
      <div className="container mt-4">
        <div className="card">
          <div className="card-header">
            <h4>
              Add Email Template
              <Link
                to="/admin/view-template"
                className="btn btn-primary btn-sm float-end"
              >
                <i className="fa fa-eye" aria-hidden="true"></i>
                Email Template
              </Link>
            </h4>
          </div>

          <div className="card-body">
            <div className="row mb-4">
              <div className="col-sm-6 col-offset-1">
                <div className="form-outline">
                  <label className="form-label" htmlFor="name">
                    Template Name
                  </label>
                  <input
                    type="text"
                    id="name"
                    className="form-control"
                    name="name"
                    value={template.name}
                    onChange={handleInput}
                  />
                  <span className="text-danger">
                    {template.errors_list.name}
                  </span>
                </div>
              </div>
            </div>
            <JoditEditor
              value={contents}
              onChange={(newContent) => setContents(newContent)}
            />
            {/* <EmailEditor ref={emailEditorRef} /> */}
            <button className="btn btn-sm btn-primary" onClick={exportHtml}>
              ADD
            </button>
          </div>
        </div>
      </div>
      <ToastContainer />
    </>
  );
};

export default AddTemplate;

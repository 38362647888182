import React, { useEffect, useState } from "react";
import { read, utils } from "xlsx";
import axios from "axios";
import { ToastContainer } from "react-toastify";
import { notify } from "./toastify/TostifyMessages";
const ViewLeads = () => {
  // const dbFields = { first_name: "", last_name: "" };
  const [tblFields, setTblFields] = useState([]);
  const [duplicate, setDuplicate] = useState([]);
  const [message, setMessage] = useState();
  const [categories, setCategories] = useState([]);
  const [subCategories, setSubcategories] = useState([]);
  const [cate, setCate] = useState({});
  const [subCate, setSubcate] = useState([]);
  const available = true;
  // const tblFields = [
  //   "first_name",
  //   "last_name",
  //   "email",
  //   "phone",
  //   "city",
  //   "state",
  //   "country",
  //   "zipcode",
  // ];
  const [leads, setLeads] = useState([]);
  const [heading, setHeading] = useState([]);
  // const [dbField, setdbField] = useState(dbFields);
  const [dbField, setdbField] = useState([]);

  useEffect(() => {
    axios.get(`/api/db-fields`).then((res) => {
      if (res.data.status === 200) {
        setTblFields(res.data.tableFields);
      }
    });
    const getCategory = () => {
      axios.get(`/api/view-categories`).then((res) => {
        if (res.data.status === 200) {
          setCategories(res.data.categoryData);
          // console.log("leads", leads);
        }
      });
    };

    getCategory();
  }, []);

  const handleChange = (e) => {
    setdbField({ ...dbField, [e.target.name]: e.target.value });
    console.log(dbField.first);
  };

  let handleCategory = {};
  const handleCate = (e) => {
    console.log("sdkksdjfksjfl", e.target.name.slice(-1));

    handleCategory[e.target.name.slice(-1)] = e.target.value;
    setCate({
      ...cate,
      [e.target.name]: [e.target.value],
    });

    console.log("cate", cate);
    console.log("handlecate", handleCategory);
  };
  // const handleSubcate = (e) => {
  //   setSubcate({ ...subCate, [e.target.name]: [e.target.value] });
  //   console.log("subcate", subCate);
  // };

  // import file function
  const handleImport = ($event) => {
    const files = $event.target.files;
    if (files.length) {
      const file = files[0];
      const reader = new FileReader();
      reader.onload = (event) => {
        const wb = read(event.target.result);
        const sheets = wb.SheetNames;

        if (sheets.length) {
          const rows = utils.sheet_to_json(wb.Sheets[sheets[0]]);
          // setLeads(rows);
          console.log("data rows", rows);
          // if categories value not exist add category value null
          Object.entries(rows).forEach((entries) => {
            let [key, value] = entries;
            console.log("valueeee", value);
            if (!value.hasOwnProperty("category")) {
              value["category"] = "";
              console.log("key ", key, "value", value);
            }
          });
          // setHeading(Object.keys(rows[0]));
          // if category not exist in heading add category in heading as key
          const headingArray = Object.keys(rows[0]);
          console.log("headingArray", headingArray);
          if (
            !(
              headingArray.includes("Category") ||
              headingArray.includes("category")
            )
          ) {
            headingArray.push("category");
            Object.entries(rows).forEach((entry) => {
              const [key, value] = entry;
              rows[key]["category"] = "";
              console.log("key ", key, "value", value);
            });
            available = false;
          }
          console.log("myArray afetr all", headingArray);

          setHeading(headingArray);
          setLeads(rows);
          console.log("rows", rows);
        }
      };
      reader.readAsArrayBuffer(file);
    }
  };

  const storeData = () => {
    console.log("save data cate", cate[0]);
    console.log("save data", leads);
    console.log("category", cate["category0"]);
    const cateKeys = Object.keys(cate);
    console.log("cate obj values", cateKeys);
    // const leadsKeys = Object.keys(leads).length;

    console.log("subcategory", leads[0]);
    // subcategory working area
    let subCategories = [];

    for (let i = 0; i < Object.keys(leads).length; i++) {
      subCategories[i] = leads[i]["subcategory"];
    }

    console.log("sub categories array", subCategories);

    const cateObj = [];
    let arrays = {};
    // cateObj["category"] = new Array();
    for (let i = 0; i < cateKeys.length; i++) {
      let singleValue = cate[cateKeys[i]];
      // let value = singleValue[0];
      // // singleValue[i];
      // if (arrays[i] == "") {
      //   arrays[i] = value;
      // }
      console.log("single value", singleValue);
      console.log("single value", singleValue[0]);
      cateObj.push(singleValue[0]);
    }

    let arrays2 = [];

    console.log("leadssssssskeysssssss", arrays);
    console.log("handle category array", subCate[0]);
    for (let i = 0; i < Object.keys(leads).length; i++) {
      arrays2[i] = leads[i]["category"];
    }
    // working loop
    console.log("arraysss2 length", arrays2.length);
    for (let i = 0; i < cateObj.length; i++) {
      Object.entries(arrays2).forEach((entries) => {
        let [key, value] = entries;
        if (arrays2[key] === "") {
          arrays2[key] = cateObj[i];
          console.log("working looop for each keys", key, "valuuee ", value);
          console.log("klslkldskflskfl", arrays2[key]);
        }
      });
    }
    console.log("cateObj", cateObj);
    console.log("cateObjArrrayyy", arrays);
    console.log("cateObjArrrayyy2", arrays2);
    var dataStoredObj = {};
    var nextObj = {};
    var someObj = {};
    var arr = [];
    const map = new Map();
    // console.log("leads", Object.keys(leads[0])[0]);
    console.log("leads", Object.values(leads[0]));
    console.log("db field length", Object.keys(dbField).length);
    console.log("leads length", Object.keys(leads[0]).length);
    console.log("heading", heading);

    // db fields
    for (let i = 0; i < Object.keys(dbField).length; i++) {
      console.log("outer", Object.keys(dbField)[i]);

      // keys loop
      for (let j = 0; j < Object.keys(leads[0]).length; j++) {
        if (Object.keys(dbField)[i] === Object.keys(leads[0])[j]) {
          var accessKey = Object.keys(leads[0])[j];
          var dbFieldValue = Object.values(dbField)[i];

          console.log("leads first", Object.keys(leads[0]).length);
          console.log("leads first", leads[j].first);
          //object for data storing with key
          dataStoredObj[dbFieldValue] = new Array();

          for (let k = 0; k < Object.keys(leads).length; k++) {
            console.log("leads k", leads[k]);
            console.log("accessKey", accessKey);
            console.log("db field value", dbFieldValue);
            if (leads[k].hasOwnProperty(accessKey)) {
              // arr.push({ [dbFieldValue]: leads[k][accessKey] });
              dataStoredObj[dbFieldValue].push(leads[k][accessKey]);

              nextObj[dbFieldValue] = leads[k][accessKey];
              // someObj = nextObj[dbFieldValue].push(leads[k][accessKey]);
              // nextObj[dbFieldValue] = leads[k][accessKey];
              // arr[k] = dataStoredObj;
              map.set(dbFieldValue, leads[k][accessKey]);
              someObj = { [nextObj[dbFieldValue]]: leads[k][accessKey] };
            }
            // console.log("values ", leads[k].first);
          }
        }
      }
    }

    console.log("objklklklklklklklk", dataStoredObj);
    console.log("arrrrrraayyyyklklklklklklklk", arr);

    console.log("nextObj", nextObj);
    console.log("someObj", someObj);
    console.log("arr", arr);
    dataStoredObj.subcategory = subCategories;
    if (available === false) {
      dataStoredObj.category = cateObj;
    } else {
      dataStoredObj.category = arrays2;
    }
    console.log("final object", dataStoredObj);

    console.log("last leadsssssss", leads);

    console.log("test leads", leads);
    console.log("test leads", arrays2);
    console.log("next obj", cateObj);
    axios.get("/sanctum/csrf-cookie").then((response) => {
      axios.post(`/api/store-leads`, dataStoredObj).then((res) => {
        if (res.data.status === 200) {
          setMessage(res.data.message);
          notify("success", res.data.message);
          console.log("data stored", res.data.message);
        } else if (res.data.status === 400) {
          setDuplicate(res.data.duplicate);
          notify("warning", res.data.message);
          // document.getElementById("dataTbl").clear();
          var Table = document.getElementById("dataTbl");
          Table.innerHTML = "";
          console.log("error", res.data.duplicate);
        } else if (res.data.status === 403) {
          notify("error", res.data.message);
        }
      });
    });
  };

  const errors = duplicate?.map((error, idx) => {
    return (
      <tr key={idx}>
        <td>{error.email}</td>
        <td>{error.phone}</td>
      </tr>
    );
  });

  // Excel imported data table
  const table = (
    <>
      {leads != "" && (
        <>
          <div className="container-fluid" id="dataTbl">
            <div className="card">
              <div className="card-header">
                <h4>Excel Imported Data</h4>
              </div>
              <div className="card-body">
                <table className="table table-bordered table-responsive">
                  <thead>
                    <tr>
                      {heading.map((heading, index) => (
                        <td key={index}>
                          {heading}
                          {heading === "categories_id" ||
                          heading === "category" ||
                          heading === "Category" ||
                          heading === "Categories_id" ||
                          heading === "categories_id" ||
                          heading === "categories" ||
                          heading === "Categories" ||
                          heading === "subcategory" ||
                          heading === "subCategory" ||
                          heading === "subcategories" ||
                          heading === "subcategories_id" ||
                          heading === "subCategories" ? (
                            ""
                          ) : (
                            <select
                              className="form-control"
                              name={heading}
                              onChange={handleChange}
                            >
                              {tblFields.map((columns, index1) => (
                                <option key={index1} value={dbField.columns}>
                                  {columns}
                                </option>
                              ))}
                            </select>
                          )}
                          {/* <select
                            className="form-control"
                            name={heading}
                            onChange={handleChange}
                          >
                            {tblFields.map((columns, index1) => (
                              <option key={index1} value={dbField.columns}>
                                {columns}
                              </option>
                            ))}
                          </select> */}
                        </td>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {leads?.map((lead, index) => (
                      // print leads data
                      <tr key={index}>
                        {/* heading loop for leads key */}
                        {heading.map((keys, idx1) => (
                          <td key={idx1}>
                            {/* check if category data not present then show drop down */}
                            {keys === "category" && lead[keys] == "" ? (
                              <select
                                onChange={handleCate}
                                className="form-control"
                                name={`category${index}`}
                              >
                                <option value="">---------</option>
                                {categories.map((category, idss) => {
                                  return (
                                    <option value={category.name} key={idss}>
                                      {category.name}
                                    </option>
                                  );
                                })}
                              </select>
                            ) : (
                              lead[keys]
                            )}
                          </td>
                        ))}
                      </tr>
                    ))}
                  </tbody>
                </table>
                <div className="row">
                  <div className="col-md-6">
                    <button
                      className="btn btn-sm btn-primary"
                      onClick={storeData}
                    >
                      Save Data
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
  return (
    <>
      <div className="row mb-2 mt-5">
        <div className="col-sm-6 offset-3">
          <div className="row">
            <div className="col-md-6">
              <div className="input-group">
                {/* <select>
                  <option>----</option>
                  <option>Default Category</option>
                  <option>Set One by One</option>
                </select> */}
              </div>

              <div className="input-group">
                <div className="custom-file">
                  <input
                    type="file"
                    className="form-control"
                    id="customFile"
                    required
                    onChange={handleImport}
                    accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                  />
                  {/* <input
                    type="file"
                    name="file"
                    className="custom-file-input"
                    id="inputGroupFile"
                    required
                    onChange={handleImport}
                    accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                  />
                  <label className="custom-file-label" htmlFor="inputGroupFile">
                    Choose file
                  </label> */}
                </div>
              </div>
            </div>
            {/* <div className="col-md-6">
              <button
                onClick={handleExport}
                className="btn btn-primary float-right"
              >
                Export <i className="fa fa-download"></i>
              </button>
            </div> */}
            {message && <div className="alert alert-success">{message}</div>}
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-sm-12 ">
          {duplicate != "" && (
            <div className="container">
              <div className="card">
                <div className="card-header">
                  <h4>Please Remove Following Duplicate Data</h4>
                </div>
                <div className="card-body">
                  <table className="table table-bordered">
                    <thead>
                      <tr>
                        {/* <td>Name</td> */}
                        <td>Email</td>
                        <td>Phone</td>
                      </tr>
                    </thead>
                    <tbody>{errors}</tbody>
                  </table>
                </div>
              </div>
            </div>
          )}
          {table}
        </div>
      </div>
      <ToastContainer />
    </>
  );
};

export default ViewLeads;

import React, { useEffect, useState } from "react";
import { ToastContainer } from "react-toastify";
import { Link, useParams } from "react-router-dom";
import axios from "axios";
import { notify } from "../toastify/TostifyMessages";
import { Col, Modal, ModalBody, ModalHeader, Row } from "reactstrap";

const SendCompagions2 = () => {
  const [searchKey, setSearchKey] = useState("");
  const [compagions, setCompagions] = useState([]);
  const [emails, setEmails] = useState(0);
  const [modal, setModal] = useState(false);
  const [compagionSend, setCompagionSend] = useState(false);
  const [compagionCancel, setCompagionCancel] = useState(false);
  const [confirm, setConfirm] = useState({
    confirmation: "",
  });

  const params = useParams();

  const id = params["id"];
  const [ids, setId] = useState(id);
  useEffect(() => {
    axios.get(`/api/get-compagions/${id}`).then((res) => {
      if (res.data.status === 200) {
        setCompagions(res.data.compagions);
        setEmails(res.data.emails);
        console.log("compagions", res.data.compagions);
      }
    });
  }, [id]);

  const sendCompagion = (e) => {
    e.preventDefault();

    const validation = confirm.confirmation;
    console.log("validation", validation);
    console.log("validation", ids);
    let id2 = ids;
    if (validation === "gliXeNtecH") {
      notify("success", "Authentication sucessfull");
      setModal(false);
      setCompagionSend(true);
      axios.get(`/api/send-mail/${id2}`).then((res) => {
        if (res.data.status === 200) {
          notify("success", res.data.message);
          //   setCompagions(res.data.compagions);
          //   setEmails(res.data.emails);
          // console.log("leads", leads);
        }
      });
    } else {
      setModal(false);
      notify("error", "Failed to Authenticate....");
    }
  };

  const handleConfirm = (e) => {
    setConfirm({
      [e.target.name]: e.target.value,
    });
  };

  const cancelCompagions = (e) => {
    e.preventDefault();
    setCompagionCancel(false);
    setCompagionSend(false);
    let id2 = ids;
    axios.get(`/api/cancel-emails/${id2}`).then((res) => {
      if (res.data.status === 200) {
        notify("warning", res.data.message);
      } else {
        notify("error", res.data.message);
      }
    });
  };
  return (
    <>
      <div className="container-fluid mt-4">
        <div className="card">
          <div className="card-header">
            <h2>
              Send Mails
              <Link
                to="/admin/view-compagions"
                className="btn btn-sm btn-primary float-center mx-2"
              >
                <i className="fa fa-plus-circle" aria-hidden="true"></i>
                View Compagions
              </Link>
              {compagionSend && (
                <button
                  className="btn btn-sm btn-primary mx-2"
                  onClick={() => setCompagionCancel(true)}
                >
                  Cancel Compagions
                </button>
              )}
              {/* <form className="d-none d-md-inline-block form-inline ms-auto me-0 me-md-3 my-2 my-md-0"> */}
              <div className="float-end">
                <div className="input-group">
                  <input
                    className="form-control"
                    type="text"
                    placeholder="Search for..."
                    aria-label="Search for..."
                    name="search"
                    onChange={(e) => setSearchKey(e.target.value)}
                    value={searchKey}
                    aria-describedby="btnNavbarSearch"
                  />
                  <button
                    className="btn btn-primary"
                    id="btnNavbarSearch"
                    type="button"
                  >
                    <i className="fas fa-search"></i>
                  </button>
                </div>
              </div>
              {/* </form> */}
              {/* <Link
      to="/admin/add-leads"
      className="btn btn-sm btn-primary float-center"
    >
      Add Lead
    </Link> */}
            </h2>
          </div>
          <div className="card-body">
            <table className="table table-bordered">
              <thead>
                <tr>
                  <td>Compagion </td>
                  <td>Category </td>
                  <td>Subcategory </td>
                  <td> Domain </td>
                  <td> Template </td>

                  {/* <td>Edit</td> */}
                  <td>Start</td>
                </tr>
              </thead>
              <tbody>
                {compagions?.map((compagion, key) => {
                  return (
                    <tr key={key}>
                      <td>
                        {compagion.name} ({emails} Emails)
                      </td>
                      <td>{compagion.category?.name}</td>
                      {/* <td>{compagion.categories_id}</td> */}
                      <td>{compagion.subcategory?.name}</td>
                      <td>{compagion.domain.name}</td>
                      <td>{compagion.template?.name}</td>

                      {/* <td>
                        <Link
                          to={`/admin/edit-template/${compagion.id}`}
                          className="btn btn-sm btn-primary"
                        >
                          Edit
                        </Link>
                      </td> */}
                      <td>
                        <button
                          className="btn btn-sm btn-primary"
                          onClick={() => setModal(true)}
                          // onClick={(e) => sendCompagion(e, compagion.id)}
                        >
                          Start Compagions
                        </button>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
            <Modal size="lg" isOpen={modal} toggle={() => setModal(!modal)}>
              <ModalHeader>Confirmation</ModalHeader>
              <ModalBody>
                <Row md={12}>
                  <Col>
                    <div>
                      <p>
                        type <h4>gliXeNtecH</h4>
                      </p>
                    </div>
                    <div>
                      <input
                        type="text"
                        name="confirmation"
                        className="form-control"
                        value={confirm.confirmation}
                        onChange={handleConfirm}
                      />
                      <input
                        type="hidden"
                        name="id"
                        className="form-control"
                        value={id}
                        onChange={(e) => setId(e.target.value)}
                      />
                    </div>
                    <button
                      onClick={(e) => sendCompagion(e)}
                      className="btn btn-sm btn-danger mt-2"
                    >
                      Submit
                    </button>
                  </Col>
                </Row>
              </ModalBody>
            </Modal>

            <Modal
              size="lg"
              isOpen={compagionCancel}
              toggle={() => setCompagionCancel(!compagionCancel)}
            >
              <ModalHeader>Confirmation</ModalHeader>
              <ModalBody>
                <div className="row text-center">
                  <div className="col-md-12">
                    <p>Attention Administrators! Proceed with caution!</p>
                    <p>Are you sure you want to cancel</p>
                    <p>
                      Once you confirm, all emails are cancelled. Think it over,
                      and only proceed if you're 100% certain
                    </p>
                  </div>
                  <div className="col-md-6 text-center">
                    <button
                      onClick={(e) => cancelCompagions(e)}
                      className="btn btn-sm btn-danger mt-2"
                    >
                      Yes
                    </button>
                  </div>
                  <div className="col-md-6">
                    <button
                      className="btn btn-sm btn-secondary"
                      onClick={(e) => setCompagionCancel(false)}
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </ModalBody>
            </Modal>
          </div>
        </div>
      </div>

      <ToastContainer />
    </>
  );
};

export default SendCompagions2;

import { toast } from "react-toastify";

const toastStyle = {
  position: "top-right",
  autoClose: 5000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: false,
  progress: undefined,
  theme: "colored",
};

const notify = (result, message) => {
  if (result === "success") {
    return toast.success(message, toastStyle);
  } else if (result === "error") {
    return toast.error(message, toastStyle);
  } else if (result === "warning") {
    return toast.warning(message, toastStyle);
  }
};

export { notify };

import React, { useEffect, useState } from "react";
import axios from "axios";
import { utils, writeFile } from "xlsx";
import { ToastContainer } from "react-toastify";
import { Col, Modal, ModalBody, ModalHeader, Row } from "reactstrap";
import "react-toastify/dist/ReactToastify.css";
import "../testStyle.css";
import { notify } from "./toastify/TostifyMessages";
import PaginatedLeads from "./leads/PaginatedLeads";
import Pagination from "./pagination/Pagination";

const ManageLeads = () => {
  const [leads, setLeads] = useState([]);
  const [searchKey, setSearchKey] = useState("");
  const [modal, setModal] = useState(false);
  const [id, setId] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [leadsPerPage, setleadsPerPage] = useState(10);

  const viewLeads = () => {
    axios.get(`/api/view-leads`).then((res) => {
      if (res.data.status === 200) {
        setLeads(res.data.leadsData);
        // console.log("leads", leads);
      }
    });
  };

  useEffect(() => {
    viewLeads();
    // axios.get(`/api/search-leads/${searchKey}`).then((res) => {
    //   if (res.data.status === 200) {
    //     setLeads(res.data.leads);
    //     // console.log("leads", leads);
    //   }
    // });
    if (searchKey !== "") {
      axios.get(`/api/search-leads/${searchKey}`).then((res) => {
        if (res.data.status === 200) {
          setLeads(res.data.leads);
          // console.log("leads", leads);
        }
      });
    }
  }, [searchKey]);
  const handleExport = () => {
    const headings = [
      // heading,
      [
        "id",
        "first",
        "last",
        "email",
        "phone",
        "city",
        "state",
        "country",
        "zipcode",
        "created_at",
        "updated_at",
        "profession",
        "categories_id",
        "sub_categories_id",
      ],
    ];
    const wb = utils.book_new();
    const ws = utils.json_to_sheet([]);
    utils.sheet_add_aoa(ws, headings);
    utils.sheet_add_json(ws, leads, { origin: "A2", skipHeader: true });
    utils.book_append_sheet(wb, ws, "Report");
    writeFile(wb, "Leads Report.xlsx");
  };
  const deleteLead = (e) => {
    e.preventDefault();
    // const thisClicked = e.currentTarget;
    // thisClicked.innerText = "Deleting";

    const ids = id;

    setModal(false);
    console.log("ids", ids);
    axios.delete(`/api/delete-leads/${ids}`).then((res) => {
      if (res.data.status === 200) {
        // swal("Success", res.data.message, "success");
        // thisClicked.closest("tr").remove();
        notify("success", res.data.message);
      } else if (res.data.status === 400) {
        // swal("Warning", res.data.message, "warning");
        // thisClicked.innerText = "Delete";
        notify("warning", res.data.message);
      }
    });
    viewLeads();
  };

  const reloadPage = () => {
    setSearchKey("");
    viewLeads();
  };

  const cancelDelete = () => {
    setModal(false);
  };

  const lastLeadIndex = currentPage * leadsPerPage;
  const firstLeadIndex = lastLeadIndex - leadsPerPage;
  const currentLeads = leads.slice(firstLeadIndex, lastLeadIndex);

  return (
    <>
      <div className="container-fluid my-4" style={{ overflow: "hidden" }}>
        <div className="row">
          <div className="col-md-offset-1 col-md-12">
            <div className="panel">
              <div className="panel-heading">
                <div className="row">
                  <div className="col col-sm-12 col-xs-12">
                    <h4 className="title">
                      Leads <span>List</span>
                      <div className="float-end">
                        <div className="btn_group">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Search"
                            name="search"
                            onChange={(e) => setSearchKey(e.target.value)}
                            value={searchKey}
                          />
                          <button
                            className="btn btn-default"
                            data-tip="Reload"
                            onClick={reloadPage}
                          >
                            <i className="fa fa-sync-alt"></i>
                          </button>

                          <button
                            className="btn btn-default"
                            data-tip="Excel"
                            onClick={handleExport}
                          >
                            <i className="fas fa-file-excel"></i>
                          </button>
                        </div>
                      </div>
                    </h4>
                  </div>
                  {/* <div className="col-sm-9 col-xs-12 text-right">
                    <div className="btn_group">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Search"
                        name="search"
                        onChange={(e) => setSearchKey(e.target.value)}
                        value={searchKey}
                      />
                      <button
                        className="btn btn-default"
                        data-tip="Reload"
                        onClick={reloadPage}
                      >
                        <i className="fa fa-sync-alt"></i>
                      </button>

                      <button
                        className="btn btn-default"
                        data-tip="Excel"
                        onClick={handleExport}
                      >
                        <i className="fas fa-file-excel"></i>
                      </button>
                    </div>
                  </div> */}
                </div>
              </div>
              <div className="panel-body table-responsive">
                <table className="table">
                  <thead>
                    <tr>
                      <th>First Name</th>
                      <th>Last Name</th>
                      <th>Email</th>
                      <th>phone</th>
                      <th>city</th>
                      <th>state</th>
                      <th>country</th>
                      <th>zipcode</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    <PaginatedLeads
                      currentLeads={currentLeads}
                      setId={setId}
                      setModal={setModal}
                    />
                  </tbody>
                </table>
              </div>
              <div className="panel-footer">
                <Pagination
                  totalRecord={leads.length}
                  recordPerPage={leadsPerPage}
                  setCurrentPage={setCurrentPage}
                  recordCount={currentLeads.length}
                />
                {/* <div className="row">
                  <div className="col col-sm-6 col-xs-6">
                    showing <b>5</b> out of <b>25</b> entries
                  </div>
                  <div className="col-sm-6 col-xs-6">
                    <ul className="pagination hidden-xs pull-right">
                      <li>
                        <a href="#">&gt;</a>
                      </li>
                      <li className="active">
                        <a href="#">1</a>
                      </li>
                      <li>
                        <a href="#">2</a>
                      </li>
                      <li>
                        <a href="#">3</a>
                      </li>
                      <li>
                        <a href="#">4</a>
                      </li>
                      <li>
                        <a href="#">5</a>
                      </li>
                      <li>
                        <a href="#">&gt;</a>
                      </li>
                    </ul>
                    <ul className="pagination visible-xs pull-right">
                      <li>
                        <a href="#">&gt;</a>
                      </li>
                      <li>
                        <a href="#">&gt;</a>
                      </li>
                    </ul>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        </div>
        <Modal size="lg" isOpen={modal} toggle={() => setModal(!modal)}>
          <ModalHeader>Confirmation</ModalHeader>
          <ModalBody>
            <Row md={12}>
              <Col>
                <div>
                  <p>Are you sure to made it default..</p>
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                <button
                  data-tip="confirm"
                  onClick={(e) => deleteLead(e)}
                  className="btn-sm btn-secondary mt-2"
                >
                  Yes
                </button>
              </Col>
              <Col>
                <button
                  data-tip="cancel"
                  onClick={(e) => cancelDelete(e)}
                  className="btn-sm btn-info mt-2"
                >
                  No
                </button>
              </Col>
            </Row>
          </ModalBody>
        </Modal>
        <ToastContainer />
      </div>
    </>
  );
};

export default ManageLeads;

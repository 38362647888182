import React from "react";
import { Link } from "react-router-dom";
import { notify } from "../toastify/TostifyMessages";
import axios from "axios";
const Paginatedcategories = ({ currentCategories, setId, setModal }) => {
  const setValues = (idd, modalToggle) => {
    setModal(modalToggle);
    setId(idd);
  };

  const deleteCategory = (e, id) => {
    e.preventDefault();
    // const thisClicked = e.currentTarget;
    // thisClicked.innerText = "Deleting";

    axios.delete(`/api/delete-category/${id}`).then((res) => {
      if (res.data.status === 200) {
        // swal("Success", res.data.message, "success");
        // thisClicked.closest("tr").remove();
        notify("success", res.data.message);
      } else if (res.data.status === 400) {
        // swal("Warning", res.data.message, "warning");
        // thisClicked.innerText = "Delete";
        notify("warning", res.data.message);
      }
    });
  };

  return (
    <>
      {currentCategories?.map((category, key) => {
        return (
          <tr key={key}>
            <td>{category.name}</td>
            <td>
              {category.default_category === 1 ? (
                <p>default category</p>
              ) : (
                <button
                  data-tip="default"
                  className="btn btn-secondary btn-sm"
                  onClick={() => setValues(category.id, true)}
                >
                  set Default
                </button>
              )}
            </td>
            <td>
              <ul className="action-list">
                <li>
                  <Link
                    className="text-white"
                    to={`/admin/edit-categories/${category.id}`}
                  >
                    <i className="fa fa-edit"></i>
                  </Link>
                </li>
                <li>
                  <h6
                    data-tip="delete"
                    delete="delete"
                    onClick={(e) => deleteCategory(e, category.id)}
                  >
                    <i className="fa fa-trash"></i>
                  </h6>
                </li>
              </ul>
            </td>
          </tr>
        );
      })}
    </>
  );
};

export default Paginatedcategories;

import React, { useEffect, useState } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { notify } from "../toastify/TostifyMessages";
const AddLeads = () => {
  const [leads, setLeads] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    city: "",
    state: "",
    country: "",
    zipcode: "",
    category_id: "",
    subCategory_id: "",
    errors_list: [],
  });
  const [categories, setCategories] = useState([]);
  const [subCategories, setSubcategories] = useState([]);
  useEffect(() => {
    const getCategory = () => {
      axios.get(`/api/view-categories`).then((res) => {
        if (res.data.status === 200) {
          setCategories(res.data.categoryData);
          // console.log("leads", leads);
        }
      });
    };

    getCategory();
  }, []);
  const [message, setMessage] = useState();

  const handleInput = (e) => {
    setLeads({
      ...leads,
      [e.target.name]: e.target.value,
    });

    const id = e.target.value;
    axios.get(`/api/get-subcategory/${id}`).then((res) => {
      if (res.data.status === 200) {
        setSubcategories(res.data.subcategoryData);
        console.warn("subcategory data", res.data.subcategoryData);
      } else if (res.data.status === 404) {
        console.warn("else part");
        // navigate("/admin");
      }
    });
  };

  const submitForm = (e) => {
    e.preventDefault();

    const formData = new FormData();

    formData.append("first_name", leads.firstName);
    formData.append("last_name", leads.lastName);
    formData.append("email", leads.email);
    formData.append("phone", leads.phone);
    formData.append("category", leads.category_id);
    formData.append("subcategory", leads.subCategory_id);
    formData.append("city", leads.city);
    formData.append("state", leads.state);
    formData.append("country", leads.country);
    formData.append("zipcode", leads.zipcode);

    axios.post(`/api/add-leads`, formData).then((res) => {
      if (res.data.status === 200) {
        console.log("success", res.data.message);
        notify("success", res.data.message);
        setMessage(res.data.message);
        document.getElementById("add_lead").reset();
      } else if (res.data.status === 400) {
        console.log("errors", res.data.errors);
        setLeads({
          ...leads,
          errors_list: res.data.errors,
        });
        notify("error", res.data.message);
      }
    });
  };
  return (
    <>
      <div className="container mt-4">
        <div className="card">
          <div className="card-header">
            <h4>
              Add Leads
              <Link
                to="/admin/manage"
                className="btn btn-primary btn-sm float-end"
              >
                <i className="fa fa-eye" aria-hidden="true"></i>
                View Leads
              </Link>
            </h4>
          </div>

          <div className="card-body">
            <form onSubmit={submitForm} id="add_lead">
              {message && <div className="alert alert-success">{message}</div>}
              <div className="row mb-4">
                <div className="col">
                  <div className="form-outline">
                    <label className="form-label" htmlFor="form3Example1">
                      First name
                    </label>
                    <input
                      type="text"
                      id="form3Example1"
                      className="form-control"
                      name="firstName"
                      value={leads.firstName}
                      onChange={handleInput}
                    />
                    <span className="text-danger">
                      {leads.errors_list.first_name}
                    </span>
                  </div>
                </div>
                <div className="col">
                  <div className="form-outline">
                    <label className="form-label" htmlFor="form3Example2">
                      Last name
                    </label>
                    <input
                      type="text"
                      id="form3Example2"
                      className="form-control"
                      name="lastName"
                      value={leads.lastName}
                      onChange={handleInput}
                    />
                    <span className="text-danger">
                      {leads.errors_list.last_name}
                    </span>
                  </div>
                </div>
              </div>
              <div className="row mb-4">
                <div className="col">
                  <div className="form-outline">
                    <label className="form-label" htmlFor="form3Example2">
                      Category
                    </label>
                    <select
                      className="form-control"
                      name="category_id"
                      onChange={handleInput}
                      value={leads.category_id}
                    >
                      <option value="">select category</option>
                      {categories.map((category, idx) => {
                        return (
                          <option value={category.id} key={idx}>
                            {category.name}
                          </option>
                        );
                      })}
                    </select>
                    <span className="text-danger">
                      {/* {leads.errors_list.last_name} */}
                    </span>
                  </div>
                </div>
                <div className="col">
                  <div className="form-outline">
                    <label className="form-label" htmlFor="form3Example2">
                      Sub category
                    </label>
                    <select
                      name="subCategory_id"
                      className="form-control"
                      onChange={handleInput}
                      value={leads.subCategory_id}
                    >
                      {subCategories.map((subcategory, id) => {
                        return (
                          <option value={subcategory.id}>
                            {subcategory.name}
                          </option>
                        );
                      })}
                    </select>
                    <span className="text-danger">
                      {/* {leads.errors_list.last_name} */}
                    </span>
                  </div>
                </div>
              </div>

              <div className="row mb-4">
                <div className="col">
                  <div className="form-outline">
                    <label className="form-label" htmlFor="form3Example1">
                      Email
                    </label>
                    <input
                      type="email"
                      id="form3Example1"
                      className="form-control"
                      name="email"
                      value={leads.email}
                      onChange={handleInput}
                    />
                    <span className="text-danger">
                      {leads.errors_list.email}
                    </span>
                  </div>
                </div>
                <div className="col">
                  <div className="form-outline">
                    <label className="form-label" htmlFor="form3Example2">
                      Phone
                    </label>
                    <input
                      type="number"
                      id="form3Example2"
                      className="form-control"
                      name="phone"
                      value={leads.phone}
                      onChange={handleInput}
                    />
                    <span className="text-danger">
                      {leads.errors_list.phone}
                    </span>
                  </div>
                </div>
              </div>
              <div className="row mb-4">
                <div className="col">
                  <div className="form-outline">
                    <label className="form-label" htmlFor="form3Example1">
                      City
                    </label>
                    <input
                      type="text"
                      id="form3Example1"
                      className="form-control"
                      name="city"
                      value={leads.city}
                      onChange={handleInput}
                    />
                    <span className="text-danger">
                      {leads.errors_list.city}
                    </span>
                  </div>
                </div>
                <div className="col">
                  <div className="form-outline">
                    <label className="form-label" htmlFor="form3Example2">
                      State
                    </label>
                    <input
                      type="text"
                      id="form3Example2"
                      className="form-control"
                      name="state"
                      value={leads.state}
                      onChange={handleInput}
                    />
                    <span className="text-danger">
                      {leads.errors_list.state}
                    </span>
                  </div>
                </div>
              </div>
              <div className="row mb-4">
                <div className="col">
                  <div className="form-outline">
                    <label className="form-label" htmlFor="form3Example1">
                      Country
                    </label>
                    <input
                      type="text"
                      id="form3Example1"
                      className="form-control"
                      name="country"
                      value={leads.country}
                      onChange={handleInput}
                    />
                    <span className="text-danger">
                      {leads.errors_list.country}
                    </span>
                  </div>
                </div>
                <div className="col">
                  <div className="form-outline">
                    <label className="form-label" htmlFor="form3Example2">
                      Zipcode
                    </label>
                    <input
                      type="text"
                      id="form3Example2"
                      className="form-control"
                      name="zipcode"
                      value={leads.zipcode}
                      onChange={handleInput}
                    />
                    <span className="text-danger">
                      {leads.errors_list.zipcode}
                    </span>
                  </div>
                </div>
              </div>

              <button type="submit" className="btn btn-primary btn-block mb-4">
                Add
              </button>
            </form>
          </div>
        </div>
      </div>
      <ToastContainer />
    </>
  );
};

export default AddLeads;

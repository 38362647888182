import React from "react";

const Pagination = ({
  totalRecord,
  recordPerPage,
  setCurrentPage,
  recordCount,
}) => {
  let pages = [];
  for (let i = 1; i <= Math.ceil(totalRecord / recordPerPage); i++) {
    pages.push(i);
  }
  return (
    <>
      <div className="row">
        <div className="col col-sm-6 col-xs-6">
          showing <b>{recordCount}</b> out of <b>{totalRecord}</b> entries
        </div>
        <div className="col-sm-6 col-xs-6">
          <ul className="pagination hidden-xs pull-right">
            {pages.map((page, index) => {
              return (
                <li key={index}>
                  <button onClick={() => setCurrentPage(page)}>{page}</button>
                </li>
              );
            })}

            {/* <li className="active">
              <Link>1</Link>
            </li>
            <li>
              <a href="#">2</a>
            </li> */}
          </ul>
          {/* <ul className="pagination visible-xs pull-right">
            <li>
              <a href="#">&gt;</a>
            </li>
            <li>
              <a href="#">&gt;</a>
            </li>
          </ul> */}
        </div>
      </div>
    </>
  );
};

export default Pagination;

import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import axios from "axios";
import { notify } from "../toastify/TostifyMessages";
const EditDomain = () => {
  const [domain, setDomain] = useState([]);
  const [servers, setServers] = useState([]);

  const params = useParams();
  const id = params["id"];
  useEffect(() => {
    axios.get(`/api/edit-domain/${id}`).then((res) => {
      if (res.data.status === 200) {
        setDomain(res.data.domain);
        console.warn("domain", res.data.domain);
      } else if (res.data.status === 404) {
        console.warn("else part");
        // navigate("/admin");
      }
    });
    axios.get(`/api/get-servers`).then((res) => {
      if (res.data.status === 200) {
        setServers(res.data.serverData);
      }
    });
  }, [id]);
  const handleInput = (e) => {
    setDomain({
      ...domain,
      [e.target.name]: [e.target.value],
    });
  };
  const submitForm = (e) => {
    e.preventDefault();

    const form = new FormData();
    form.append("name", domain.name);
    form.append("server_id", domain.servers_id);
    form.append("password", domain.password);

    axios.post(`/api/update-domain/${id}`, form).then((res) => {
      if (res.data.status === 200) {
        console.log("success", res.data.message);
        notify("success", res.data.message);
      } else if (res.data.status === 400) {
        console.log("errors", res.data.errors);

        notify("error", res.data.message);
      }
    });
  };
  return (
    <>
      <div className="container mt-4">
        <div className="card">
          <div className="card-header">
            <h4>
              Edit Domain
              <Link
                to="/admin/view-domain"
                className="btn btn-primary btn-sm float-end"
              >
                <i className="fa fa-eye" aria-hidden="true"></i>
                View Domain
              </Link>
            </h4>
          </div>

          <div className="card-body">
            <form onSubmit={submitForm}>
              <div className="row mb-4">
                <div className="col-sm-6 col-offset-1">
                  <div className="form-outline">
                    <label className="form-label" htmlFor="name">
                      Domain
                    </label>
                    <input
                      type="text"
                      id="name"
                      className="form-control"
                      name="name"
                      value={domain.name}
                      onChange={handleInput}
                    />
                    <span className="text-danger">
                      {/* {fromEmail.errors_list.name} */}
                    </span>
                  </div>
                </div>
                <div className="col-sm-6 col-offset-1">
                  <div className="form-outline">
                    <label className="form-label" htmlFor="password">
                      Password
                    </label>
                    <input
                      type="text"
                      id="password"
                      className="form-control"
                      name="password"
                      value={domain.password}
                      onChange={handleInput}
                    />
                    <span className="text-danger">
                      {/* {fromEmail.errors_list.name} */}
                    </span>
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="form-outline">
                    <label className="form-label" htmlFor="form3Example1">
                      Server
                    </label>
                    <select
                      className="form-control"
                      name="servers_id"
                      onChange={handleInput}
                      value={domain.servers_id}
                    >
                      <option value="">select Server</option>
                      {servers.map((item) => {
                        return (
                          <option value={item.id} key={item.id}>
                            {item.host}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </div>
              </div>

              <button type="submit" className="btn btn-primary btn-block mb-4">
                Update
              </button>
            </form>
          </div>
        </div>
      </div>
      <ToastContainer />
    </>
  );
};

export default EditDomain;

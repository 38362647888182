import React, { useState } from "react";
import Home from "./Home";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { ToastContainer } from "react-toastify";
import { notify } from "../backend/toastify/TostifyMessages";

const Login = () => {
  const [login, setLogin] = useState({
    email: "",
    password: "",
  });

  const navigate = useNavigate();
  const handleInput = (e) => {
    // e.persist();
    setLogin({ ...login, [e.target.name]: e.target.value });
  };

  const submitForm = (e) => {
    e.preventDefault();
    const data = {
      email: login.email,
      password: login.password,
    };
    console.log("email", login);
    axios.get("/sanctum/csrf-cookie").then((response) => {
      axios.post("api/login", data).then((res) => {
        if (res.data.status === 200) {
          localStorage.setItem("auth_token", res.data.token);
          localStorage.setItem("auth_user", res.data.username);

          console.log("success", res.data.message);
          navigate("/admin/dashboard");
        } else if (res.data.status === 401) {
          notify("warning", res.data.message);
          console.log("warning", res.data.message);
        } else {
          console.log("errors", res.data.validation_errors);
          notify("error", res.data.message);
          // setLogin({
          //   ...loginInput,
          //   error_list: res.data.validation_errors,
          // });
        }
      });
    });
    // let email = localStorage.getItem("email");
    // let password = localStorage.getItem("password");
    // if (email === login.email && password === login.password) {
    //   console.log(
    //     "password matched... if you redirect to home please set local storage variables email and passwrd"
    //   );
    //   navigate("/admin/dashboard");
    // } else {
    //   console.log("password not matched");
    //   console.log(
    //     "if you use correct login detail but redirect to home please set local storage variables email and passwrd"
    //   );
    //   navigate("/");
    // }
  };
  return (
    <>
      <Home />
      <section className="vh-100" style={{ backgroundColor: "#9A616D" }}>
        <div className="container py-5 h-100">
          <div className="row d-flex justify-content-center align-items-center h-100">
            <div className="col col-xl-10">
              <div className="card" style={{ borderRadius: "1rem" }}>
                <div className="row g-0">
                  <div className="col-md-6 col-lg-5 d-none d-md-block">
                    <img
                      src="https://mdbcdn.b-cdn.net/img/Photos/new-templates/bootstrap-login-form/img1.webp"
                      alt="login form"
                      className="img-fluid"
                      style={{ borderRadius: "1rem 0 0 1rem" }}
                    />
                  </div>
                  <div className="col-md-6 col-lg-7 d-flex align-items-center">
                    <div className="card-body p-4 p-lg-5 text-black">
                      <form onSubmit={submitForm}>
                        <div className="d-flex align-items-center mb-3 pb-1">
                          <i
                            className="fas fa-cubes fa-2x me-3"
                            style={{ color: "#ff6219" }}
                          ></i>
                          <span className="h1 fw-bold mb-0">CRM Logo</span>
                        </div>

                        <h5
                          className="fw-normal mb-3 pb-3"
                          style={{ letterSpacing: "1px" }}
                        >
                          Sign into your account
                        </h5>

                        <div className="form-outline mb-4">
                          <label
                            className="form-label"
                            htmlFor="form2Example17"
                          >
                            Email address
                          </label>
                          <input
                            type="email"
                            id="form2Example17"
                            className="form-control form-control-lg"
                            name="email"
                            value={login.email}
                            onChange={handleInput}
                          />
                        </div>

                        <div className="form-outline mb-4">
                          <label
                            className="form-label"
                            htmlFor="form2Example27"
                          >
                            Password
                          </label>
                          <input
                            type="password"
                            id="form2Example27"
                            className="form-control form-control-lg"
                            name="password"
                            value={login.password}
                            onChange={handleInput}
                          />
                        </div>

                        <div className="pt-1 mb-4">
                          <button
                            className="btn btn-dark btn-lg btn-block"
                            type="submit"
                          >
                            Login
                          </button>
                        </div>

                        <Link className="small text-muted" to="#!">
                          Forgot password?
                        </Link>
                        <p
                          className="mb-5 pb-lg-2"
                          style={{ color: "#393f81" }}
                        >
                          Don't have an account?{" "}
                          <Link to="#!" style={{ color: "#393f81" }}>
                            Register here
                          </Link>
                        </p>
                        <Link href="#!" className="small text-muted">
                          Terms of use.
                        </Link>
                        <Link to="#!" className="small text-muted">
                          Privacy policy
                        </Link>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <ToastContainer />
    </>
  );
};
export default Login;

import React, { useEffect, useState } from "react";
import { ToastContainer } from "react-toastify";
import axios from "axios";
import { notify } from "../toastify/TostifyMessages";
import { Col, Modal, ModalBody, ModalHeader, Row } from "reactstrap";
import PaginatedCompagions from "./PaginatedCompagions";
import Pagination from "../pagination/Pagination";
const Successfull = () => {
  const [searchKey, setSearchKey] = useState("");
  const [compagions, setCompagions] = useState([]);
  const [ids, setIds] = useState(0);
  const [id, setId] = useState(0);
  const [modal, setModal] = useState(false);
  const [compagionModal, setCompagionModal] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [compagionsPerPage, setcompagionsPerPage] = useState(10);

  const [confirm, setConfirm] = useState({
    confirmation: "",
  });

  useEffect(() => {
    getCompagions();
    if (searchKey !== "") {
      axios.get(`/api/search-compagions/${searchKey}`).then((res) => {
        if (res.data.status === 200) {
          setCompagions(res.data.compagion);
          // console.log("leads", leads);
        }
      });
    }
  }, [searchKey]);

  const getCompagions = () => {
    axios.get(`/api/successful-compagions`).then((res) => {
      if (res.data.status === 200) {
        // console.log("Compagions", res.data.compagions.data);
        setCompagions(res.data.compagions);
        // setCompagions(res.data.compagions.data);
        // setEmails(res.data.emails);
        // console.log("leads", leads);
      }
    });
  };

  const sendCompagion = (e) => {
    e.preventDefault();

    const validation = confirm.confirmation;
    console.log("validation", validation);
    console.log("validation", ids);
    let id2 = ids;
    if (validation === "gliXeNtecH") {
      notify("success", "Authentication sucessfull");
      setCompagionModal(false);
      axios.get(`/api/send-mail/${id2}`).then((res) => {
        if (res.data.status === 200) {
          notify("success", res.data.message);
        }
      });
    } else {
      setCompagionModal(false);
      notify("error", "Failed to Authenticate....");
    }
  };

  const handleConfirm = (e) => {
    setConfirm({
      [e.target.name]: e.target.value,
    });
  };
  const deleteCompagion = (e) => {
    e.preventDefault();
    // const thisClicked = e.currentTarget;
    // thisClicked.innerText = "Deleting";
    setModal(false);
    const idd = id;
    axios.delete(`/api/delete-compagion/${idd}`).then((res) => {
      if (res.data.status === 200) {
        // swal("Success", res.data.message, "success");
        // thisClicked.closest("tr").remove();
        notify("success", res.data.message);
      } else if (res.data.status === 400) {
        // swal("Warning", res.data.message, "warning");
        // thisClicked.innerText = "Delete";
        notify("warning", res.data.message);
      }
    });
    getCompagions();
  };
  const reloadPage = () => {
    setSearchKey("");
    getCompagions();
  };

  const cancelDelete = () => {
    setModal(false);
  };

  const lastCompagionIndex = currentPage * compagionsPerPage;
  const firstCompagionIndex = lastCompagionIndex - compagionsPerPage;
  const currentCompagions = compagions.slice(
    firstCompagionIndex,
    lastCompagionIndex
  );

  return (
    <>
      <div className="container-fluid my-4">
        <div className="row">
          <div className="col-md-offset-1 col-md-12">
            <div className="panel">
              <div className="panel-heading">
                <div className="row">
                  <div className="col col-sm-12 col-xs-12">
                    <h4 className="title">
                      Successfull Compagions <span>List</span>
                      <div className="float-end">
                        <div className="btn_group">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Search"
                            name="search"
                            onChange={(e) => setSearchKey(e.target.value)}
                            value={searchKey}
                          />
                          <button
                            className="btn btn-default"
                            data-tip="Reload"
                            onClick={reloadPage}
                          >
                            <i className="fa fa-sync-alt"></i>
                          </button>
                        </div>
                      </div>
                    </h4>
                  </div>
                  {/* <div className="col-sm-9 col-xs-12 text-right">
                      <div className="btn_group">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Search"
                          name="search"
                          onChange={(e) => setSearchKey(e.target.value)}
                          value={searchKey}
                        />
                        <button
                          className="btn btn-default"
                          data-tip="Reload"
                          onClick={reloadPage}
                        >
                          <i className="fa fa-sync-alt"></i>
                        </button>
  
                        <button
                          className="btn btn-default"
                          data-tip="Excel"
                          onClick={handleExport}
                        >
                          <i className="fas fa-file-excel"></i>
                        </button>
                      </div>
                    </div> */}
                </div>
              </div>
              <div className="panel-body table-responsive">
                <table className="table">
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th>Category</th>
                      <th>Domain</th>
                      <th>Template</th>

                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    <PaginatedCompagions
                      currentCompagions={currentCompagions}
                      setId={setId}
                      setModal={setModal}
                      setIds={setIds}
                      setCompagionModal={setCompagionModal}
                    />
                  </tbody>
                </table>
              </div>
              <div className="panel-footer">
                <Pagination
                  totalRecord={compagions.length}
                  recordPerPage={compagionsPerPage}
                  setCurrentPage={setCurrentPage}
                  recordCount={currentCompagions.length}
                />
                {/* <div className="row">
                    <div className="col col-sm-6 col-xs-6">
                      showing <b>5</b> out of <b>25</b> entries
                    </div>
                    <div className="col-sm-6 col-xs-6">
                      <ul className="pagination hidden-xs pull-right">
                        <li>
                          <a href="#">&gt;</a>
                        </li>
                        <li className="active">
                          <a href="#">1</a>
                        </li>
                        <li>
                          <a href="#">2</a>
                        </li>
                        <li>
                          <a href="#">3</a>
                        </li>
                        <li>
                          <a href="#">4</a>
                        </li>
                        <li>
                          <a href="#">5</a>
                        </li>
                        <li>
                          <a href="#">&gt;</a>
                        </li>
                      </ul>
                      <ul className="pagination visible-xs pull-right">
                        <li>
                          <a href="#">&gt;</a>
                        </li>
                        <li>
                          <a href="#">&gt;</a>
                        </li>
                      </ul>
                    </div>
                  </div> */}
              </div>
            </div>
          </div>
        </div>
        <Modal size="lg" isOpen={modal} toggle={() => setModal(!modal)}>
          <ModalHeader>Confirmation</ModalHeader>
          <ModalBody>
            <Row md={12}>
              <Col>
                <div>
                  <p>Are you sure to delet it...</p>
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                <button
                  data-tip="confirm"
                  onClick={(e) => deleteCompagion(e)}
                  className="btn-sm btn-secondary mt-2"
                >
                  Yes
                </button>
              </Col>
              <Col>
                <button
                  data-tip="cancel"
                  onClick={(e) => cancelDelete(e)}
                  className="btn-sm btn-info mt-2"
                >
                  No
                </button>
              </Col>
            </Row>
          </ModalBody>
        </Modal>

        <Modal
          size="lg"
          isOpen={compagionModal}
          toggle={() => setCompagionModal(!compagionModal)}
        >
          <ModalHeader>Confirmation</ModalHeader>
          <ModalBody>
            <Row md={12}>
              <Col>
                <div>
                  <p>
                    type <h4>gliXeNtecH</h4>
                  </p>
                </div>
                <div>
                  <input
                    type="text"
                    name="confirmation"
                    className="form-control"
                    value={confirm.confirmation}
                    onChange={handleConfirm}
                  />
                  <input
                    type="hidden"
                    name="id"
                    className="form-control"
                    value={ids}
                    onChange={(e) => setIds(e.target.value)}
                  />
                </div>
                <button
                  onClick={(e) => sendCompagion(e)}
                  className="btn btn-sm btn-danger mt-2"
                >
                  Submit
                </button>
              </Col>
            </Row>
          </ModalBody>
        </Modal>
        <ToastContainer />
      </div>
    </>
  );
};

export default Successfull;

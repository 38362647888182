import React, { useEffect, useState } from "react";
import axios from "axios";
import { ToastContainer } from "react-toastify";
import { notify } from "../toastify/TostifyMessages";
import { Col, Modal, ModalBody, ModalHeader, Row } from "reactstrap";
import "../../testStyle.css";
import Pagination from "../pagination/Pagination";
import Paginatedcategories from "./Paginatedcategories";

const ViewCategories = () => {
  const [categories, setCategories] = useState([]);
  const [searchKey, setSearchKey] = useState("");
  const [modal, setModal] = useState(false);
  const [id, setId] = useState(0);

  const [currentPage, setCurrentPage] = useState(1);
  const [categoriesPerPage, setCategoriesPerPage] = useState(10);

  useEffect(() => {
    viewCategories();
    if (searchKey !== "") {
      axios.get(`/api/search-categories/${searchKey}`).then((res) => {
        if (res.data.status === 200) {
          setCategories(res.data.categores);
          // console.log("leads", leads);
        }
      });
    }
  }, [searchKey]);
  // view all categories
  const viewCategories = () => {
    axios.get(`/api/view-categories`).then((res) => {
      if (res.data.status === 200) {
        setCategories(res.data.categoryData);
        // console.log("leads", leads);
      }
    });
  };

  // const deleteCategory = (e, id) => {
  //   e.preventDefault();

  //   axios.delete(`/api/delete-category/${id}`).then((res) => {
  //     if (res.data.status === 200) {
  //       notify("success", res.data.message);
  //     } else if (res.data.status === 400) {
  //       notify("warning", res.data.message);
  //     }
  //   });
  //   viewCategories();
  // };

  // const setValues = (idd, modalToggle) => {
  //   setModal(modalToggle);
  //   setId(idd);
  // };
  const setDefault = (e) => {
    e.preventDefault();
    setModal(false);
    axios.get(`/api/default-category/${id}`).then((res) => {
      if (res.data.status === 200) {
        setCategories(res.data.categoryData);
        notify("success", res.data.message);
        // console.log("leads", leads);
      }
    });
    console.log(id);
  };
  const cancelDefault = (e) => {
    setModal(false);
  };
  const reloadPage = (e) => {
    e.preventDefault();
    setSearchKey("");
    viewCategories();
  };

  const lastCategoryIndex = currentPage * categoriesPerPage;
  const firstCategoryIndex = lastCategoryIndex - categoriesPerPage;
  const currentCategories = categories.slice(
    firstCategoryIndex,
    lastCategoryIndex
  );

  console.log("current categories", currentCategories);
  return (
    <>
      <div className="container-fluid my-4">
        <div className="row">
          <div className="col-md-offset-1 col-md-12">
            <div className="panel">
              <div className="panel-heading">
                <div className="row">
                  <div className="col col-sm-12 col-xs-12">
                    <h4 className="title">
                      Categories <span>List</span>
                      <div className="float-end">
                        <div className="btn_group">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Search"
                            name="search"
                            onChange={(e) => setSearchKey(e.target.value)}
                            value={searchKey}
                          />
                          <button
                            className="btn btn-default"
                            data-tip="Reload"
                            onClick={reloadPage}
                          >
                            <i className="fa fa-sync-alt"></i>
                          </button>
                        </div>
                      </div>
                    </h4>
                  </div>
                  {/* <div className="col-sm-9 col-xs-12 float-end">
                    <div className="btn_group">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Search"
                        name="search"
                        onChange={(e) => setSearchKey(e.target.value)}
                        value={searchKey}
                      />
                      <button
                        className="btn btn-default"
                        data-tip="Reload"
                        onClick={reloadPage}
                      >
                        <i className="fa fa-sync-alt"></i>
                      </button>
                    </div>
                  </div> */}
                </div>
              </div>
              <div className="panel-body table-responsive">
                <table className="table">
                  <thead>
                    <tr>
                      <th>Category Name</th>
                      <th>Default category</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    <Paginatedcategories
                      currentCategories={currentCategories}
                      // setCategories={setCategories}
                      setId={setId}
                      setModal={setModal}
                    />
                  </tbody>
                </table>
              </div>
              <div className="panel-footer">
                <Pagination
                  totalRecord={categories.length}
                  recordPerPage={categoriesPerPage}
                  setCurrentPage={setCurrentPage}
                  recordCount={currentCategories.length}
                />
                {/* <div className="row">
                  <div className="col col-sm-6 col-xs-6">
                    showing <b>5</b> out of <b>25</b> entries
                  </div>
                  <div className="col-sm-6 col-xs-6">
                    <ul className="pagination hidden-xs pull-right">
                      <li>
                        <a href="#">&gt;</a>
                      </li>
                      <li className="active">
                        <a href="#">1</a>
                      </li>
                      <li>
                        <a href="#">2</a>
                      </li>
                      <li>
                        <a href="#">3</a>
                      </li>
                      <li>
                        <a href="#">4</a>
                      </li>
                      <li>
                        <a href="#">5</a>
                      </li>
                      <li>
                        <a href="#">&gt;</a>
                      </li>
                    </ul>
                    <ul className="pagination visible-xs pull-right">
                      <li>
                        <a href="#">&gt;</a>
                      </li>
                      <li>
                        <a href="#">&gt;</a>
                      </li>
                    </ul>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        </div>
        <Modal size="lg" isOpen={modal} toggle={() => setModal(!modal)}>
          <ModalHeader>Confirmation</ModalHeader>
          <ModalBody>
            <Row md={12}>
              <Col>
                <div>
                  <p>Are you sure to made it default..</p>
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                <button
                  data-tip="confirm"
                  onClick={(e) => setDefault(e)}
                  className="btn-sm btn-secondary mt-2"
                >
                  Yes
                </button>
              </Col>
              <Col>
                <button
                  data-tip="cancel"
                  onClick={(e) => cancelDefault(e)}
                  className="btn-sm btn-info mt-2"
                >
                  No
                </button>
              </Col>
            </Row>
          </ModalBody>
        </Modal>
        <ToastContainer />
      </div>
    </>
  );
};

export default ViewCategories;

import React from "react";
// import { notify } from "../toastify/TostifyMessages";
// import axios from "axios";

const PaginatedCompagions = ({
  currentCompagions,
  setId,
  setModal,
  setCompagionModal,
  setIds,
}) => {
  const setValues = (e, idd) => {
    setModal(true);
    setId(idd);
  };
  const popUpconfirmation = (e, id) => {
    setCompagionModal(true);
    setIds(id);
  };

  // const cancelEmails = (e, id) => {
  //   axios.get(`/api/cancel-emails`).then((res) => {
  //     if (res.data.status === 200) {
  //       notify("success", res.data.message);
  //       // console.log("leads", leads);
  //     }
  //   });
  // };
  return (
    <>
      {" "}
      {currentCompagions?.map((compagions, key) => {
        return (
          <tr key={key}>
            <td>
              {compagions.name ? compagions.name : compagions.compagion.name}
            </td>
            <td>
              {compagions.category
                ? compagions.category.name
                : compagions.compagion.category.name}
            </td>
            <td>
              {compagions.domain
                ? compagions.domain.name
                : compagions.compagion.domain.name}
            </td>
            <td>
              {compagions.template
                ? compagions.template.name
                : compagions.compagion.template.name}
            </td>
            <td>
              <ul className="action-list">
                <li>
                  {compagions.category ? (
                    <h6
                      onClick={(e) => popUpconfirmation(e, compagions.id)}
                      className="text-white"
                    >
                      <i className="fa fa-envelope" aria-hidden="true"></i>
                    </h6>
                  ) : (
                    <h6
                      onClick={(e) =>
                        popUpconfirmation(e, compagions.compagion.id)
                      }
                      className="text-white"
                    >
                      <i className="fa fa-envelope" aria-hidden="true"></i>
                    </h6>
                  )}
                </li>
                {compagions.category ? (
                  <li>
                    <h6
                      data-tip="delete"
                      title="delete"
                      onClick={(e) => setValues(e, compagions.id)}
                    >
                      <i className="fa fa-trash"></i>
                    </h6>
                  </li>
                ) : (
                  ""
                )}

                {/* <li>
                  <h6
                    data-tip="cancel"
                    title="cancael"
                    onClick={(e) => cancelEmails(e, compagion.id)}
                  >
                    cancel
                  </h6>
                </li> */}
              </ul>
            </td>
          </tr>
        );
      })}
    </>
  );
};

export default PaginatedCompagions;

import React from "react";
import { Link } from "react-router-dom";

const Sidebar = () => {
  return (
    <nav className="sb-sidenav accordion custom-sidebar" id="sidenavAccordion">
      <div className="sb-sidenav-menu">
        <div className="nav">
          <div className="sb-sidenav-menu-heading">Core</div>
          <Link className="nav-link text-white" to="/admin/dashboard">
            <div className="sb-nav-link-icon">
              <i className="fas fa-tachometer-alt"></i>
            </div>
            Dashboard
          </Link>
          <div className="sb-sidenav-menu-heading">Interface</div>
          <Link
            className="nav-link collapsed text-white"
            to="#"
            data-bs-toggle="collapse"
            data-bs-target="#collapseLeads"
            aria-expanded="false"
            aria-controls="collapseProducts"
          >
            <div className="sb-nav-link-icon">
              <i className="fa fa-users" aria-hidden="true"></i>
            </div>
            Leads
            <div className="sb-sidenav-collapse-arrow">
              <i className="fas fa-angle-down"></i>
            </div>
          </Link>
          <div
            className="collapse"
            id="collapseLeads"
            aria-labelledby="headingOne"
            data-bs-parent="#sidenavAccordion"
          >
            <nav className="sb-sidenav-menu-nested nav">
              <Link className="nav-link text-white" to="/admin/view">
                Sheet
              </Link>
              <Link className="nav-link text-white" to="/admin/manage">
                Manage
              </Link>
              <Link className="nav-link text-white" to="/admin/add-leads">
                Add Leads
              </Link>
            </nav>
          </div>
          {/* <div className="sb-sidenav-menu-heading">Interface</div> */}
          <Link
            className="nav-link collapsed text-white"
            to="#"
            data-bs-toggle="collapse"
            data-bs-target="#collapseCategory"
            aria-expanded="false"
            aria-controls="collapseCategory"
          >
            <div className="sb-nav-link-icon">
              <i className="fa fa-list-alt" aria-hidden="true"></i>
            </div>
            Categories
            <div className="sb-sidenav-collapse-arrow">
              <i className="fas fa-angle-down"></i>
            </div>
          </Link>
          <div
            className="collapse"
            id="collapseCategory"
            aria-labelledby="headingOne"
            data-bs-parent="#sidenavAccordion"
          >
            <nav className="sb-sidenav-menu-nested nav">
              <Link className="nav-link text-white" to="/admin/add-category">
                Add Category
              </Link>
              <Link className="nav-link text-white" to="/admin/view-categories">
                View Categories
              </Link>
              <Link className="nav-link text-white" to="/admin/add-subcategory">
                Add subcategory
              </Link>
              <Link
                className="nav-link text-white"
                to="/admin/view-subcategories"
              >
                View subcategory
              </Link>
            </nav>
          </div>
          {/* <Link
            className="nav-link collapsed text-white"
            to="#"
            data-bs-toggle="collapse"
            data-bs-target="#collapseSubCategory"
            aria-expanded="false"
            aria-controls="collapseSubCategory"
          >
            <div className="sb-nav-link-icon">
              <i className="fa fa-list-ol"></i>
            </div>
            Sub Categories
            <div className="sb-sidenav-collapse-arrow">
              <i className="fas fa-angle-down"></i>
            </div>
          </Link>
          <div
            className="collapse"
            id="collapseSubCategory"
            aria-labelledby="headingOne"
            data-bs-parent="#sidenavAccordion"
          >
            <nav className="sb-sidenav-menu-nested nav">
              <Link className="nav-link text-white" to="/admin/add-subcategory">
                Add
              </Link>
              <Link
                className="nav-link text-white"
                to="/admin/view-subcategories"
              >
                View
              </Link>
            </nav>
          </div> */}
          <Link
            className="nav-link collapsed text-white"
            to="#"
            data-bs-toggle="collapse"
            data-bs-target="#collapseEmails"
            aria-expanded="false"
            aria-controls="collapseEmails"
          >
            <div className="sb-nav-link-icon">
              <i className="fas fa-columns"></i>
            </div>
            Email Content
            <div className="sb-sidenav-collapse-arrow">
              <i className="fas fa-angle-down"></i>
            </div>
          </Link>
          <div
            className="collapse text-white"
            id="collapseEmails"
            aria-labelledby="headingOne"
            data-bs-parent="#sidenavAccordion"
          >
            <nav className="sb-sidenav-menu-nested nav">
              <Link
                className="nav-link text-white"
                to="/admin/add-email-content"
              >
                Add Content
              </Link>
              <Link className="nav-link text-white" to="/admin/email-content">
                View Content
              </Link>
            </nav>
          </div>
          <Link
            className="nav-link collapsed text-white"
            to="#"
            data-bs-toggle="collapse"
            data-bs-target="#collapseServer"
            aria-expanded="false"
            aria-controls="collapseServer"
          >
            <div className="sb-nav-link-icon">
              <i className="fa fa-server" aria-hidden="true"></i>
            </div>
            Servers
            <div className="sb-sidenav-collapse-arrow">
              <i className="fas fa-angle-down"></i>
            </div>
          </Link>
          <div
            className="collapse"
            id="collapseServer"
            aria-labelledby="headingOne"
            data-bs-parent="#sidenavAccordion"
          >
            <nav className="sb-sidenav-menu-nested nav">
              <Link className="nav-link text-white" to="/admin/add-server">
                Add Server
              </Link>
              <Link className="nav-link text-white" to="/admin/view-server">
                View Server
              </Link>
            </nav>
          </div>
          <Link
            className="nav-link collapsed text-white"
            to="#"
            data-bs-toggle="collapse"
            data-bs-target="#senderEmail"
            aria-expanded="false"
            aria-controls="collapseEmail"
          >
            <div className="sb-nav-link-icon">
              <i className="fa fa-envelope" aria-hidden="true"></i>
            </div>
            ReplyTo Email
            <div className="sb-sidenav-collapse-arrow">
              <i className="fas fa-angle-down"></i>
            </div>
          </Link>
          <div
            className="collapse"
            id="senderEmail"
            aria-labelledby="headingOne"
            data-bs-parent="#sidenavAccordion"
          >
            <nav className="sb-sidenav-menu-nested nav">
              <Link className="nav-link text-white" to="/admin/add-email">
                Add Email
              </Link>
              <Link className="nav-link text-white" to="/admin/view-email">
                View Emails
              </Link>
            </nav>
          </div>
          <Link
            className="nav-link collapsed text-white"
            to="#"
            data-bs-toggle="collapse"
            data-bs-target="#domain"
            aria-expanded="false"
            aria-controls="collapseDomain"
          >
            <div className="sb-nav-link-icon">
              <i className="fa fa-globe" aria-hidden="true"></i>
            </div>
            Domains
            <div className="sb-sidenav-collapse-arrow">
              <i className="fas fa-angle-down"></i>
            </div>
          </Link>
          <div
            className="collapse"
            id="domain"
            aria-labelledby="headingOne"
            data-bs-parent="#sidenavAccordion"
          >
            <nav className="sb-sidenav-menu-nested nav">
              <Link className="nav-link text-white" to="/admin/add-domain">
                Add Domain
              </Link>
              <Link className="nav-link text-white" to="/admin/view-domain">
                View Domain
              </Link>
            </nav>
          </div>
          <Link
            className="nav-link collapsed text-white"
            to="#"
            data-bs-toggle="collapse"
            data-bs-target="#template"
            aria-expanded="false"
            aria-controls="collapseDomain"
          >
            <div className="sb-nav-link-icon">
              <i className="fa fa-file" aria-hidden="true"></i>
            </div>
            Templates
            <div className="sb-sidenav-collapse-arrow">
              <i className="fas fa-angle-down"></i>
            </div>
          </Link>
          <div
            className="collapse"
            id="template"
            aria-labelledby="headingOne"
            data-bs-parent="#sidenavAccordion"
          >
            <nav className="sb-sidenav-menu-nested nav">
              <Link className="nav-link text-white" to="/admin/add-template">
                Add Template
              </Link>
              <Link className="nav-link text-white" to="/admin/view-template">
                View Template
              </Link>
            </nav>
          </div>
          <Link
            className="nav-link collapsed text-white"
            to="#"
            data-bs-toggle="collapse"
            data-bs-target="#compagion"
            aria-expanded="false"
            aria-controls="collapseCompagion"
          >
            <div className="sb-nav-link-icon">
              <i className="fa fa-bullhorn" aria-hidden="true"></i>
            </div>
            Compagions
            <div className="sb-sidenav-collapse-arrow">
              <i className="fas fa-angle-down"></i>
            </div>
          </Link>
          <div
            className="collapse"
            id="compagion"
            aria-labelledby="headingOne"
            data-bs-parent="#sidenavAccordion"
          >
            <nav className="sb-sidenav-menu-nested nav">
              <Link className="nav-link text-white" to="/admin/add-compagion">
                Add
              </Link>
              <Link className="nav-link text-white" to="/admin/view-compagions">
                View
              </Link>
              <Link
                className="nav-link text-white"
                to="/admin/successfull-compagions"
              >
                Successfull
              </Link>
              <Link
                className="nav-link text-white"
                to="/admin/cancelled-compagions"
              >
                Cancelled
              </Link>
            </nav>
          </div>
        </div>
      </div>
      {/* <div className="sb-sidenav-footer">
        <div className="small">Logged in as:</div>
        Start Bootstrap
      </div> */}
    </nav>
  );
};
export default Sidebar;

import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import { Link } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { notify } from "../toastify/TostifyMessages";
import JoditEditor from "jodit-react";
const EditContent = () => {
  const [content, setContent] = useState([]);
  const [isChecked, setIsChecked] = useState(false);
  const [initialContent, setInitialConent] = useState("");
  console.log(initialContent);

  const params = useParams();
  const id = params["id"];
  useEffect(() => {
    axios.get(`/api/edit-email-content/${id}`).then((res) => {
      if (res.data.status === 200) {
        setContent(res.data.content);
        setIsChecked(content.enable);
        setInitialConent(res.data.content.message);
        // console.warn("content data", res.data.content);
      } else if (res.data.status === 404) {
        console.warn("else part");
        // navigate("/admin");
      }
    });
  }, [id, content]);
  const [contents, setContents] = useState("");
  const handleInput = (e) => {
    setContent({
      ...content,
      [e.target.name]: [e.target.value],
    });
  };

  const submitForm = (e) => {
    e.preventDefault();

    const form = new FormData();
    form.append("title", content.title);
    form.append("subject", content.subject);
    form.append("message", contents);
    form.append("enable", content.enable);

    axios.post(`/api/update-content/${id}`, form).then((res) => {
      if (res.data.status === 200) {
        // console.log("success", res.data.message);
        notify("success", res.data.message);
      } else if (res.data.status === 400) {
        // console.log("errors", res.data.errors);

        notify("error", res.data.message);
      }
    });
  };
  return (
    <>
      <div className="container mt-4">
        <div className="card">
          <div className="card-header">
            <h4>
              Edit Email Content
              <Link
                to="/admin/email-content"
                className="btn btn-primary btn-sm float-end"
              >
                <i className="fa fa-eye" aria-hidden="true"></i>
                View Email Content
              </Link>
            </h4>
          </div>

          <div className="card-body">
            <form onSubmit={submitForm}>
              <div className="row mb-4">
                <div className="col-sm-6 col-offset-1">
                  <div className="form-outline">
                    <label className="form-label" htmlFor="title">
                      Email Title
                    </label>
                    <input
                      type="text"
                      id="title"
                      className="form-control"
                      name="title"
                      value={content.title}
                      onChange={handleInput}
                    />
                    <span className="text-danger">
                      {/* {content.errors_list.title} */}
                    </span>
                  </div>
                </div>
                <div className="col-sm-6 col-offset-1">
                  <div className="form-outline">
                    <label className="form-label" htmlFor="subject">
                      Email Subject
                    </label>
                    <input
                      type="text"
                      id="subject"
                      className="form-control"
                      name="subject"
                      value={content.subject}
                      onChange={handleInput}
                    />
                    <span className="text-danger">
                      {/* {content.errors_list.subject} */}
                    </span>
                  </div>
                </div>
              </div>
              <div className="row mb-4">
                <div className="col-sm-12 col-offset-1">
                  <div className="form-outline">
                    <label className="form-label" htmlFor="message">
                      Email Message
                    </label>
                    <JoditEditor
                      value={content.message}
                      onChange={(newContent) => setContents(newContent)}
                    />
                    {/* <textarea
                      id="message"
                      cols="10"
                      rows="8"
                      className="form-control"
                      type="text"
                      name="message"
                      value={content.message}
                      onChange={handleInput}
                    ></textarea> */}
                    <span className="text-danger">
                      {/* {content.errors_list.message} */}
                    </span>
                  </div>
                </div>
              </div>
              <div className="row mb-4">
                <div className="col-sm-6 col-offset-1">
                  <div className="form-outline">
                    <label htmlFor="enable">Enable</label>
                    <input
                      id="enable"
                      type="checkbox"
                      name="enable"
                      checked={content.enable}
                      value={content.enable}
                      onClick={handleInput}
                    />
                  </div>
                </div>
              </div>

              <button type="submit" className="btn btn-primary btn-block mb-4">
                Update
              </button>
            </form>
          </div>
        </div>
      </div>
      <ToastContainer />
    </>
  );
};

export default EditContent;

import React, { useEffect, useState } from "react";
import axios from "axios";
import { Link } from "react-router-dom";

const Dashboard = () => {
  const [dashboard, setDashboard] = useState([]);

  useEffect(() => {
    dashboardData();
  }, []);

  const dashboardData = () => {
    axios.get(`/api/dashboard`).then((res) => {
      if (res.data.status === 200) {
        // console.log("Compagions", res.data.compagions.data);
        setDashboard(res.data.dashboardData);
        // setCompagions(res.data.compagions.data);
        // setEmails(res.data.emails);
        // console.log("leads", leads);
      }
    });
  };
  return (
    <div className="container-fluid">
      <div className="d-sm-flex align-items-center justify-content-between mb-4">
        <h1 className="h3 mb-0 text-gray-800 text-white">Dashboard</h1>
        {/* <a
          href="#"
          className="d-none d-sm-inline-block btn btn-sm btn-primary shadow-sm"
        >
          <i className="fas fa-download fa-sm text-white-50"></i> Generate Report
        </a> */}
      </div>
      <div className="row">
        <div className="col-xl-3 col-md-6 mb-4">
          <Link to="/admin/view-compagions" className="text-white">
            <div className="card border-left-primary shadow h-100 py-2">
              <div className="card-body">
                <div className="row no-gutters align-items-center">
                  <div className="col mr-2">
                    <div className="text-xs font-weight-bold text-primary text-uppercase mb-1">
                      Total Created Compagions
                    </div>
                    <div className="h5 mb-0 font-weight-bold text-dark">
                      {dashboard.totalCompagions}
                    </div>
                  </div>
                  <div className="col-auto text-dark">
                    {/* <i className="fas fa-calendar fa-2x text-gray-300"></i> */}
                    <i className="fa fa-bullhorn" aria-hidden="true"></i>
                  </div>
                </div>
              </div>
            </div>
          </Link>
        </div>
        <div className="col-xl-3 col-md-6 mb-4">
          <Link to="/admin/successfull-compagions" className="text-white">
            <div className="card border-left-primary shadow h-100 py-2">
              <div className="card-body">
                <div className="row no-gutters align-items-center">
                  <div className="col mr-2">
                    <div className="text-xs font-weight-bold text-primary text-uppercase mb-1">
                      Successfull Compagions
                    </div>
                    <div className="h5 mb-0 font-weight-bold text-dark">
                      {dashboard.successfullCompagion}
                    </div>
                  </div>
                  <div className="col-auto text-dark">
                    {/* <i className="fas fa-calendar fa-2x text-gray-300"></i> */}
                    <i className="fa fa-bullhorn" aria-hidden="true"></i>
                  </div>
                </div>
              </div>
            </div>
          </Link>
        </div>
        <div className="col-xl-3 col-md-6 mb-4">
          <Link className="text-white">
            <div className="card border-left-primary shadow h-100 py-2">
              <div className="card-body">
                <div className="row no-gutters align-items-center">
                  <div className="col mr-2">
                    <div className="text-xs font-weight-bold text-primary text-uppercase mb-1">
                      Cancelled Compagions
                    </div>
                    <div className="h5 mb-0 font-weight-bold text-dark">
                      {dashboard.cancelledCompagion}
                    </div>
                  </div>
                  <div className="col-auto text-dark">
                    {/* <i className="fas fa-calendar fa-2x text-gray-300"></i> */}
                    <i className="fa fa-bullhorn" aria-hidden="true"></i>
                  </div>
                </div>
              </div>
            </div>
          </Link>
        </div>
        <div className="col-xl-3 col-md-6 mb-4">
          <Link to="/admin/manage" className="text-white">
            <div className="card border-left-primary shadow h-100 py-2">
              <div className="card-body">
                <div className="row no-gutters align-items-center">
                  <div className="col mr-2">
                    <div className="text-xs font-weight-bold text-primary text-uppercase mb-1">
                      Total Leads
                    </div>
                    <div className="h5 mb-0 font-weight-bold text-dark">
                      {dashboard.lead}
                    </div>
                  </div>
                  <div className="col-auto text-dark">
                    <i className="fa fa-users" aria-hidden="true"></i>
                  </div>
                </div>
              </div>
            </div>
          </Link>
        </div>
        <div className="col-xl-3 col-md-6 mb-4">
          <Link to="/admin/view-categories" className="text-white">
            <div className="card border-left-primary shadow h-100 py-2">
              <div className="card-body">
                <div className="row no-gutters align-items-center">
                  <div className="col mr-2">
                    <div className="text-xs font-weight-bold text-primary text-uppercase mb-1">
                      Total Categories
                    </div>
                    <div className="h5 mb-0 font-weight-bold text-dark">
                      {dashboard.category}
                    </div>
                  </div>
                  <div className="col-auto text-dark">
                    <i className="fa fa-list-alt" aria-hidden="true"></i>
                  </div>
                </div>
              </div>
            </div>
          </Link>
        </div>
        <div className="col-xl-3 col-md-6 mb-4">
          <Link to="/admin/view-domain" className="text-white">
            <div className="card border-left-primary shadow h-100 py-2">
              <div className="card-body">
                <div className="row no-gutters align-items-center">
                  <div className="col mr-2">
                    <div className="text-xs font-weight-bold text-primary text-uppercase mb-1">
                      Total Domains
                    </div>
                    <div className="h5 mb-0 font-weight-bold text-dark">
                      {dashboard.domain}
                    </div>
                  </div>
                  <div className="col-auto text-dark">
                    <i className="fa fa-globe" aria-hidden="true"></i>
                  </div>
                </div>
              </div>
            </div>
          </Link>
        </div>
        <div className="col-xl-3 col-md-6 mb-4">
          <Link to="/admin/view-template" className="text-white">
            <div className="card border-left-primary shadow h-100 py-2">
              <div className="card-body">
                <div className="row no-gutters align-items-center">
                  <div className="col mr-2">
                    <div className="text-xs font-weight-bold text-primary text-uppercase mb-1">
                      Total Templates
                    </div>
                    <div className="h5 mb-0 font-weight-bold text-dark">
                      {dashboard.template}
                    </div>
                  </div>
                  <div className="col-auto text-dark">
                    <i className="fa fa-file" aria-hidden="true"></i>
                  </div>
                </div>
              </div>
            </div>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;

import React from "react";
import { Link } from "react-router-dom";
const Home = () => {
  return (
    <>
      <nav className="navbar navbar-expand-lg navbar-light bg-dark shadow stickey-top">
        <div className="container">
          <Link className="navbar-brand text-white" to="/">
            CRM
          </Link>

          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav ms-auto"></ul>
            <Link className="nav-link text-white" to="/login">
              Login<span className="sr-only"></span>
            </Link>
          </div>
        </div>
      </nav>
    </>
  );
};

export default Home;

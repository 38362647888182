import React, { useEffect, useState } from "react";
import { ToastContainer } from "react-toastify";
import { Link } from "react-router-dom";
import axios from "axios";
import { notify } from "../toastify/TostifyMessages";
const AddDomain = () => {
  const [domain, setDomain] = useState({
    email: "",
    password: "",
    server_id: "",
    errors_list: [],
  });
  const [servers, setServers] = useState([]);
  useEffect(() => {
    axios.get(`/api/get-servers`).then((res) => {
      if (res.data.status === 200) {
        setServers(res.data.serverData);
      }
    });
  }, []);
  const handleInput = (e) => {
    setDomain({
      ...domain,
      [e.target.name]: [e.target.value],
    });
  };

  const submitForm = (e) => {
    e.preventDefault();

    let form = new FormData();
    form.append("name", domain.name);
    form.append("server_id", domain.server_id);
    form.append("password", domain.password);
    axios.post(`/api/add-domain`, form).then((res) => {
      if (res.data.status === 200) {
        console.log("success", res.data.message);
        notify("success", res.data.message);
      } else if (res.data.status === 403) {
        console.log("errors", res.data.errors);
        setDomain({
          ...domain,
          errors_list: res.data.errors,
        });
        notify("error", res.data.message);
      }
    });
  };
  return (
    <>
      <div className="container mt-4">
        <div className="card">
          <div className="card-header">
            <h4>
              Add Domain
              <Link
                to="/admin/view-domain"
                className="btn btn-primary btn-sm float-end"
              >
                <i className="fa fa-eye" aria-hidden="true"></i>
                View Domains
              </Link>
            </h4>
          </div>

          <div className="card-body">
            <form onSubmit={submitForm}>
              <div className="row mb-4">
                <div className="col-sm-6 col-offset-1">
                  <div className="form-outline">
                    <label className="form-label" htmlFor="email">
                      Domain Email
                    </label>
                    <input
                      type="text"
                      id="email"
                      className="form-control"
                      name="name"
                      value={domain.name}
                      onChange={handleInput}
                    />
                    <span className="text-danger">
                      {domain.errors_list.name}
                    </span>
                  </div>
                </div>
                <div className="col-sm-6 col-offset-1">
                  <div className="form-outline">
                    <label className="form-label" htmlFor="password">
                      Password
                    </label>
                    <input
                      type="text"
                      id="password"
                      className="form-control"
                      name="password"
                      value={domain.password}
                      onChange={handleInput}
                    />
                    <span className="text-danger">
                      {domain.errors_list.password}
                    </span>
                  </div>
                </div>
                <div className="col-sm-6 col-offset-1">
                  <div className="form-outline">
                    <label className="form-label" htmlFor="smtpServer">
                      SMTP Server
                    </label>
                    <select
                      id="smtpServer"
                      className="form-control"
                      name="server_id"
                      value={domain.server_id}
                      onChange={handleInput}
                    >
                      {servers?.map((server, id) => {
                        return (
                          <option key={id} value={server.id}>
                            {server.host}
                          </option>
                        );
                      })}
                    </select>

                    <span className="text-danger">
                      {domain.errors_list.server_id}
                    </span>
                  </div>
                </div>
              </div>

              <button type="submit" className="btn btn-primary btn-block mb-4">
                Add
              </button>
            </form>
          </div>
        </div>
      </div>
      <ToastContainer />
    </>
  );
};
export default AddDomain;

import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import axios from "axios";
import { notify } from "../toastify/TostifyMessages";

const EditCategories = () => {
  const [category, setCategory] = useState({
    name: "",
    default_category: "",
  });

  const params = useParams();
  const category_id = params;
  const id = category_id["id"];
  useEffect(() => {
    axios.get(`/api/edit-category/${id}`).then((res) => {
      if (res.data.status === 200) {
        setCategory(res.data.categoryData);
        console.warn("category data", res.data.categoryData);
      } else if (res.data.status === 404) {
        console.warn("else part");
        // navigate("/admin");
      }
    });
  }, [id]);

  const submitForm = (e) => {
    e.preventDefault();

    const form = new FormData();
    form.append("name", category.name);
    form.append("default_category", category.default_category);

    axios
      .post(`/api/update-categories/${category_id["id"]}`, form)
      .then((res) => {
        if (res.data.status === 200) {
          console.log("success", res.data.message);
          notify("success", res.data.message);
        } else if (res.data.status === 400) {
          console.log("errors", res.data.errors);

          notify("error", res.data.message);
        }
      });
  };

  const handleInput = (e) => {
    setCategory({
      ...category,
      [e.target.name]: [e.target.value],
    });
  };
  return (
    <>
      <div className="container mt-4">
        <div className="card">
          <div className="card-header">
            <h4>Edit Category</h4>
          </div>

          <div className="card-body">
            <form onSubmit={submitForm}>
              <div className="row mb-4">
                <div className="col-sm-6">
                  <div className="form-outline">
                    <label className="form-label" htmlFor="form3Example1">
                      Category Name
                    </label>
                    <input
                      type="text"
                      id="form3Example1"
                      className="form-control"
                      name="name"
                      value={category.name}
                      onChange={handleInput}
                    />
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="form-outline">
                    <label className="form-label" htmlFor="default">
                      Default Category
                    </label>
                    <select
                      id="default"
                      className="form-control"
                      name="default_category"
                      value={category.default_category}
                      onChange={handleInput}
                    >
                      <option value="">select one option</option>
                      <option
                        select={
                          category.default_category === "1" ? "selected" : ""
                        }
                        value="1"
                      >
                        Yes
                      </option>
                      <option
                        select={
                          category.default_category === "0" ? "selected" : ""
                        }
                        value="0"
                      >
                        No
                      </option>
                    </select>
                  </div>
                </div>
              </div>

              <button type="submit" className="btn btn-primary btn-block mb-4">
                Update
              </button>
            </form>
          </div>
        </div>
      </div>
      <ToastContainer />
    </>
  );
};

export default EditCategories;

import { React, useEffect, useState } from "react";
import axios from "axios";
import { Link, useParams } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { notify } from "../toastify/TostifyMessages";
const EditLeads = () => {
  const [leads, setLeads] = useState({
    first_name: "",
    last_name: "",
    email: "",
    phone: "",
    city: "",
    state: "",
    country: "",
    zipcode: "",
    categories_id: "",
    sub_categories_id: "",
  });

  const [errors, setErros] = useState({
    errors_list: [],
  });

  const [message, setMessage] = useState();
  const [categoryList, setCategoryList] = useState([]);
  const [subCategories, setSubCategories] = useState([]);
  const params = useParams();
  const lead_id = params;
  const id = lead_id["id"];
  console.log("lead_id", lead_id);
  useEffect(() => {
    axios.get(`/api/edit-leads/${id}`).then((res) => {
      if (res.data.status === 200) {
        setLeads(res.data.leadsData);
        console.warn("leads data", res.data.leadsData);
      } else if (res.data.status === 404) {
        console.warn("else part");
        // navigate("/admin");
      }
    });

    axios.get("/api/view-categories").then((res) => {
      setCategoryList(res.data.categoryData);
    });
  }, [id]);

  const handleInput = (e) => {
    const name = e.target.name;
    const id = e.target.value;
    if (name === "categories_id") {
      axios.get(`/api/get-subcategory/${id}`).then((res) => {
        if (res.data.status === 200) {
          setSubCategories(res.data.subcategoryData);
          console.warn("subcategory data", res.data.subcategoryData);
        } else if (res.data.status === 404) {
          console.warn("else part");
          // navigate("/admin");
        }
      });
    }
    setLeads({
      ...leads,
      [e.target.name]: e.target.value,
    });
  };

  const submitForm = (e) => {
    e.preventDefault();

    const formData = new FormData();

    formData.append("first_name", leads.first_name);
    formData.append("last_name", leads.last_name);
    formData.append("categories_id", leads.categories_id);
    formData.append("sub_categories_id", leads.sub_categories_id);
    formData.append("email", leads.email);
    formData.append("phone", leads.phone);
    formData.append("city", leads.city);
    formData.append("state", leads.state);
    formData.append("country", leads.country);
    formData.append("zipcode", leads.zipcode);

    axios.post(`/api/update-leads/${lead_id["id"]}`, formData).then((res) => {
      if (res.data.status === 200) {
        console.log("success", res.data.message);
        notify("success", res.data.message);
        setMessage(res.data.message);
      } else if (res.data.status === 400) {
        console.log("errors", res.data.errors);
        setErros({
          errors_list: res.data.errors,
        });
        notify("error", res.data.message);
      }
    });
    console.log("errors", errors);
  };
  return (
    <>
      <div className="container mt-4">
        <div className="card">
          <div className="card-header">
            <h4>
              Edit Leads
              <Link
                to="/admin/manage"
                className="btn btn-primary btn-sm float-end"
              >
                View Leads
              </Link>
            </h4>
          </div>

          <div className="card-body">
            <form onSubmit={submitForm}>
              {message && <div className="alert alert-success">{message}</div>}
              <div className="row mb-4">
                <div className="col">
                  <div className="form-outline">
                    <label className="form-label" htmlFor="form3Example1">
                      First name
                    </label>
                    <input
                      type="text"
                      id="form3Example1"
                      className="form-control"
                      name="first_name"
                      value={leads.first_name}
                      onChange={handleInput}
                    />
                    <span className="text-danger">
                      {errors.errors_list.first_name}
                    </span>
                  </div>
                </div>
                <div className="col">
                  <div className="form-outline">
                    <label className="form-label" htmlFor="form3Example2">
                      Last name
                    </label>
                    <input
                      type="text"
                      id="form3Example2"
                      className="form-control"
                      name="last_name"
                      value={leads.last_name}
                      onChange={handleInput}
                    />
                    <span className="text-danger">
                      {errors.errors_list.last_name}
                    </span>
                  </div>
                </div>
              </div>
              <div className="row mb-4">
                <div className="col">
                  <div className="form-outline">
                    <label className="form-label" htmlFor="form3Example2">
                      Category
                    </label>
                    <select
                      className="form-control"
                      name="categories_id"
                      onChange={handleInput}
                      value={leads.categories_id}
                    >
                      <option value="">select category</option>
                      {categoryList.map((category, idx) => {
                        return (
                          <option value={category.id} key={idx}>
                            {category.name}
                          </option>
                        );
                      })}
                    </select>
                    <span className="text-danger">
                      {/* {leads.errors_list.last_name} */}
                    </span>
                  </div>
                </div>
                <div className="col">
                  <div className="form-outline">
                    <label className="form-label" htmlFor="form3Example2">
                      Sub category
                    </label>
                    <select
                      name="sub_categories_id"
                      className="form-control"
                      onChange={handleInput}
                      value={leads.sub_categories_id}
                    >
                      {subCategories.map((subcategory, id) => {
                        return (
                          <option key={id} value={subcategory.id}>
                            {subcategory.name}
                          </option>
                        );
                      })}
                    </select>
                    <span className="text-danger">
                      {/* {leads.errors_list.last_name} */}
                    </span>
                  </div>
                </div>
              </div>
              <div className="row mb-4">
                <div className="col">
                  <div className="form-outline">
                    <label className="form-label" htmlFor="form3Example1">
                      Email
                    </label>
                    <input
                      type="email"
                      id="form3Example1"
                      className="form-control"
                      name="email"
                      value={leads.email}
                      onChange={handleInput}
                    />
                    <span className="text-danger">
                      {errors.errors_list.email}
                    </span>
                  </div>
                </div>
                <div className="col">
                  <div className="form-outline">
                    <label className="form-label" htmlFor="form3Example2">
                      Phone
                    </label>
                    <input
                      type="number"
                      id="form3Example2"
                      className="form-control"
                      name="phone"
                      value={leads.phone}
                      onChange={handleInput}
                    />
                    <span className="text-danger">
                      {errors.errors_list.phone}
                    </span>
                  </div>
                </div>
              </div>
              <div className="row mb-4">
                <div className="col">
                  <div className="form-outline">
                    <label className="form-label" htmlFor="form3Example1">
                      City
                    </label>
                    <input
                      type="text"
                      id="form3Example1"
                      className="form-control"
                      name="city"
                      value={leads.city}
                      onChange={handleInput}
                    />
                    <span className="text-danger">
                      {errors.errors_list.city}
                    </span>
                  </div>
                </div>
                <div className="col">
                  <div className="form-outline">
                    <label className="form-label" htmlFor="form3Example2">
                      State
                    </label>
                    <input
                      type="text"
                      id="form3Example2"
                      className="form-control"
                      name="state"
                      value={leads.state}
                      onChange={handleInput}
                    />
                    <span className="text-danger">
                      {errors.errors_list.state}
                    </span>
                  </div>
                </div>
              </div>
              <div className="row mb-4">
                <div className="col">
                  <div className="form-outline">
                    <label className="form-label" htmlFor="form3Example1">
                      Country
                    </label>
                    <input
                      type="text"
                      id="form3Example1"
                      className="form-control"
                      name="country"
                      value={leads.country}
                      onChange={handleInput}
                    />
                    <span className="text-danger">
                      {errors.errors_list.country}
                    </span>
                  </div>
                </div>
                <div className="col">
                  <div className="form-outline">
                    <label className="form-label" htmlFor="form3Example2">
                      Zipcode
                    </label>
                    <input
                      type="text"
                      id="form3Example2"
                      className="form-control"
                      name="zipcode"
                      value={leads.zipcode}
                      onChange={handleInput}
                    />
                    <span className="text-danger">
                      {errors.errors_list.zipcode}
                    </span>
                  </div>
                </div>
              </div>

              <button type="submit" className="btn btn-primary btn-block mb-4">
                Update
              </button>
            </form>
          </div>
        </div>
      </div>
      <ToastContainer />
    </>
  );
};
export default EditLeads;

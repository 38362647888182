import React from "react";
import { Link } from "react-router-dom";
const PaginatedContent = ({ currentContent, setModal, setId }) => {
  const setValues = (e, id) => {
    setId(id);
    setModal(true);
  };
  return (
    <>
      {currentContent?.map((content, key) => {
        return (
          <tr key={key}>
            <td>{content.title}</td>
            <td>{content.subject}</td>
            <td>
              <ul className="action-list">
                <li>
                  <Link
                    to={`/admin/edit-email-content/${content.id}`}
                    className="text-white"
                  >
                    {/* <button
                                    data-tip="edit"
                                    title="edit"
                                    className="text-primary"
                                  > */}
                    <i className="fa fa-edit"></i>
                    {/* </button> */}
                  </Link>
                </li>
                <li>
                  <h6
                    data-tip="delete"
                    title="delete"
                    onClick={(e) => setValues(e, content.id)}
                  >
                    <i className="fa fa-trash"></i>
                  </h6>
                </li>
              </ul>
            </td>
          </tr>
        );
      })}
    </>
  );
};

export default PaginatedContent;

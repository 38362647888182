import React, { useEffect, useState } from "react";
import { ToastContainer } from "react-toastify";
import axios from "axios";
import { notify } from "../toastify/TostifyMessages";
import { Col, Modal, ModalBody, ModalHeader, Row } from "reactstrap";
import PaginatedContent from "./PaginatedContent";
import Pagination from "../pagination/Pagination";

const ViewContent = () => {
  const [contents, setContents] = useState([]);
  const [searchKey, setSearchKey] = useState("");
  const [modal, setModal] = useState(false);
  const [id, setId] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [contentPerPage, setContentPerPage] = useState(10);
  useEffect(() => {
    getEmailContent();
    if (searchKey !== "") {
      searchEmails();
    }
  }, [searchKey]);

  const getEmailContent = () => {
    axios.get(`/api/email-content`).then((res) => {
      if (res.data.status === 200) {
        setContents(res.data.emailContent);
      }
    });
  };

  const searchEmails = () => {
    axios.get(`/api/search-email-contents/${searchKey}`).then((res) => {
      if (res.data.status === 200) {
        setContents(res.data.contents);
        // console.log("leads", leads);
      }
    });
  };
  const deleteContent = (e) => {
    e.preventDefault();
    // const thisClicked = e.currentTarget;
    // thisClicked.innerText = "Deleting";
    setModal(false);
    const ids = id;
    axios.delete(`/api/delete-email-content/${ids}`).then((res) => {
      if (res.data.status === 200) {
        // swal("Success", res.data.message, "success");
        // thisClicked.closest("tr").remove();
        notify("success", res.data.message);
      } else if (res.data.status === 400) {
        // swal("Warning", res.data.message, "warning");
        // thisClicked.innerText = "Delete";
        notify("warning", res.data.message);
      }
    });
    getEmailContent();
  };
  const reloadPage = () => {
    setSearchKey("");
    getEmailContent();
  };

  const setValues = (e, idd) => {
    setModal(true);
    setId(idd);
  };
  const cancelDelete = () => {
    setModal(false);
  };
  const lastContentIndex = currentPage * contentPerPage;
  const firstContentIndex = lastContentIndex - contentPerPage;
  const currentContent = contents.slice(firstContentIndex, lastContentIndex);

  console.log("current templates", currentContent);
  return (
    <>
      <div className="container-fluid my-4">
        <div className="row">
          <div className="col-md-offset-1 col-md-12">
            <div className="panel">
              <div className="panel-heading">
                <div className="row">
                  <div className="col col-sm-12 col-xs-12">
                    <h4 className="title">
                      Email Content <span>List</span>
                      <div className="float-end">
                        <div className="btn_group">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Search"
                            name="search"
                            onChange={(e) => setSearchKey(e.target.value)}
                            value={searchKey}
                          />
                          <button
                            className="btn btn-default"
                            data-tip="Reload"
                            onClick={reloadPage}
                          >
                            <i className="fa fa-sync-alt"></i>
                          </button>
                        </div>
                      </div>
                    </h4>
                  </div>
                  {/* <div className="col-sm-9 col-xs-12 text-right">
                    <div className="btn_group">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Search"
                        name="search"
                        onChange={(e) => setSearchKey(e.target.value)}
                        value={searchKey}
                      />
                      <button
                        className="btn btn-default"
                        data-tip="Reload"
                        onClick={reloadPage}
                      >
                        <i className="fa fa-sync-alt"></i>
                      </button>

                      <button
                        className="btn btn-default"
                        data-tip="Excel"
                        onClick={handleExport}
                      >
                        <i className="fas fa-file-excel"></i>
                      </button>
                    </div>
                  </div> */}
                </div>
              </div>
              <div className="panel-body table-responsive">
                <table className="table">
                  <thead>
                    <tr>
                      <th>Title</th>
                      <th>Subject</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    <PaginatedContent
                      currentContent={currentContent}
                      setModal={setModal}
                      setId={setId}
                    />
                    {/* </tr> */}
                    {/* <tr>
                      <td>2</td>
                      <td>Taylor Reyes</td>
                      <td>22</td>
                      <td>UI/UX Developer</td>
                      <td>Baileux</td>
                      <td>
                        <ul className="action-list">
                          <li>
                            <a href="#" data-tip="edit">
                              <i className="fa fa-edit"></i>
                            </a>
                          </li>
                          <li>
                            <a href="#" data-tip="delete">
                              <i className="fa fa-trash"></i>
                            </a>
                          </li>
                        </ul>
                      </td>
                    </tr>
                    <tr>
                      <td>3</td>
                      <td>Justin Block</td>
                      <td>26</td>
                      <td>Frontend Developer</td>
                      <td>Overland Park</td>
                      <td>
                        <ul className="action-list">
                          <li>
                            <a href="#" data-tip="edit">
                              <i className="fa fa-edit"></i>
                            </a>
                          </li>
                          <li>
                            <a href="#" data-tip="delete">
                              <i className="fa fa-trash"></i>
                            </a>
                          </li>
                        </ul>
                      </td>
                    </tr>
                    <tr>
                      <td>4</td>
                      <td>Sean Guzman</td>
                      <td>26</td>
                      <td>Web Designer</td>
                      <td>Gloucester</td>
                      <td>
                        <ul className="action-list">
                          <li>
                            <i className="fa fa-edit"></i>
                          </li>
                          <li>
                            <i className="fa fa-trash"></i>
                          </li>
                        </ul>
                      </td>
                    </tr>
                    <tr>
                      <td>5</td>
                      <td>Keith Carter</td>
                      <td>20</td>
                      <td>Graphic Designer</td>
                      <td>Oud-Turnhout</td>
                      <td>
                        <ul className="action-list">
                          <li>
                            <a href="#" data-tip="edit">
                              <i className="fa fa-edit"></i>
                            </a>
                          </li>
                          <li>
                            <a href="#" data-tip="delete">
                              <i className="fa fa-trash"></i>
                            </a>
                          </li>
                        </ul>
                      </td>
                    </tr> */}
                  </tbody>
                </table>
              </div>
              <div className="panel-footer">
                <Pagination
                  totalRecord={contents.length}
                  recordPerPage={contentPerPage}
                  setCurrentPage={setCurrentPage}
                  recordCount={currentContent.length}
                />
                {/* <div className="row">
                  <div className="col col-sm-6 col-xs-6">
                    showing <b>5</b> out of <b>25</b> entries
                  </div>
                  <div className="col-sm-6 col-xs-6">
                    <ul className="pagination hidden-xs pull-right">
                      <li>
                        <a href="#">&gt;</a>
                      </li>
                      <li className="active">
                        <a href="#">1</a>
                      </li>
                      <li>
                        <a href="#">2</a>
                      </li>
                      <li>
                        <a href="#">3</a>
                      </li>
                      <li>
                        <a href="#">4</a>
                      </li>
                      <li>
                        <a href="#">5</a>
                      </li>
                      <li>
                        <a href="#">&gt;</a>
                      </li>
                    </ul>
                    <ul className="pagination visible-xs pull-right">
                      <li>
                        <a href="#">&gt;</a>
                      </li>
                      <li>
                        <a href="#">&gt;</a>
                      </li>
                    </ul>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        </div>
        <Modal size="lg" isOpen={modal} toggle={() => setModal(!modal)}>
          <ModalHeader>Confirmation</ModalHeader>
          <ModalBody>
            <Row md={12}>
              <Col>
                <div>
                  <p>Are you sure to delet it...</p>
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                <button
                  data-tip="confirm"
                  onClick={(e) => deleteContent(e)}
                  className="btn-sm btn-secondary mt-2"
                >
                  Yes
                </button>
              </Col>
              <Col>
                <button
                  data-tip="cancel"
                  onClick={(e) => cancelDelete(e)}
                  className="btn-sm btn-info mt-2"
                >
                  No
                </button>
              </Col>
            </Row>
          </ModalBody>
        </Modal>
        <ToastContainer />
      </div>
    </>
  );
};

export default ViewContent;

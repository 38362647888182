import React from "react";
import { Link } from "react-router-dom";
const PaginatedLeads = ({ currentLeads, setId, setModal }) => {
  const setValues = (e, idd) => {
    setModal(true);
    setId(idd);
  };
  return (
    <>
      {currentLeads?.map((leadss, key) => {
        return (
          <tr key={key}>
            <td>{leadss.first_name}</td>
            <td>{leadss.last_name}</td>
            <td>{leadss.email}</td>
            <td>{leadss.phone}</td>
            {/* <td>{leadss.category.name}</td> */}
            {/* <td>
                        {leadss.subcategory ? leadss.subcategory.name : ""}
                      </td> */}
            <td>{leadss.city}</td>
            <td>{leadss.state}</td>
            <td>{leadss.country}</td>
            <td>{leadss.zipcode}</td>
            <td>
              <ul className="action-list">
                <li>
                  <Link
                    to={`/admin/edit-leads/${leadss.id}`}
                    className="text-white"
                  >
                    {/* <button
                                    data-tip="edit"
                                    title="edit"
                                    className="text-primary"
                                  > */}
                    <i className="fa fa-edit"></i>
                    {/* </button> */}
                  </Link>
                </li>
                <li>
                  <h6
                    data-tip="delete"
                    title="delete"
                    onClick={(e) => setValues(e, leadss.id)}
                  >
                    <i className="fa fa-trash"></i>
                  </h6>
                </li>
              </ul>
            </td>
          </tr>
        );
      })}
    </>
  );
};
export default PaginatedLeads;

import React, { useEffect, useState } from "react";
import { ToastContainer } from "react-toastify";
import { Link, useParams } from "react-router-dom";
import axios from "axios";
import { notify } from "../toastify/TostifyMessages";
const EditSubCategories = () => {
  const [subCategory, setSubcategory] = useState([]);
  const [categoryList, setCategoryList] = useState([]);

  const params = useParams();
  const subcategory_id = params;

  const id = subcategory_id["id"];

  useEffect(() => {
    axios.get("/api/view-categories").then((res) => {
      setCategoryList(res.data.categoryData);
    });

    axios.get(`/api/edit-subcategory/${id}`).then((res) => {
      if (res.data.status === 200) {
        setSubcategory(res.data.subcategoryData);
        console.warn("subcategory data", res.data.subcategoryData);
      } else if (res.data.status === 404) {
        console.warn("else part");
        // navigate("/admin");
      }
    });
  }, [id]);

  const handleInput = (e) => {
    setSubcategory({
      ...subCategory,
      [e.target.name]: [e.target.value],
    });
  };

  const submitForm = (e) => {
    e.preventDefault();

    console.log("sub in form", subCategory);
    const form = new FormData();
    form.append("name", subCategory.name);
    form.append("categories_id", subCategory.categories_id);

    axios
      .post(`/api/update-subcategories/${subcategory_id["id"]}`, form)
      .then((res) => {
        if (res.data.status === 200) {
          console.log("success", res.data.message);
          notify("success", res.data.message);
        } else if (res.data.status === 400) {
          console.log("errors", res.data.errors);

          notify("error", res.data.message);
        }
      });
  };
  return (
    <>
      <div className="container mt-4">
        <div className="card">
          <div className="card-header">
            <h4>
              Edit Sub Category
              <Link
                to="/admin/view-subcategories"
                className="btn btn-primary btn-sm float-end"
              >
                <i className="fa fa-eye" aria-hidden="true"></i>
                View Sub Categories
              </Link>
            </h4>
          </div>

          <div className="card-body">
            <form onSubmit={submitForm}>
              <div className="row mb-4">
                <div className="col-sm-6">
                  <div className="form-outline">
                    <label className="form-label" htmlFor="form3Example1">
                      Sub Category Name
                    </label>
                    <input
                      type="text"
                      id="form3Example1"
                      className="form-control"
                      name="name"
                      value={subCategory.name}
                      onChange={handleInput}
                    />
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="form-outline">
                    <label className="form-label" htmlFor="form3Example1">
                      Category
                    </label>
                    <select
                      className="form-control"
                      name="categories_id"
                      onChange={handleInput}
                      value={subCategory.categories_id}
                    >
                      <option value="">select category</option>
                      {categoryList.map((item) => {
                        return (
                          <option value={item.id} key={item.id}>
                            {item.name}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </div>
              </div>

              <button type="submit" className="btn btn-primary btn-block mb-4">
                Update
              </button>
            </form>
          </div>
        </div>
      </div>
      <ToastContainer />
    </>
  );
};

export default EditSubCategories;

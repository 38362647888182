import React, { useEffect, useState } from "react";
import { ToastContainer } from "react-toastify";
import axios from "axios";
import { notify } from "../toastify/TostifyMessages";
import { useNavigate } from "react-router-dom";

const SendCompagion = () => {
  const navigate = useNavigate();
  const [compagion, setCompagion] = useState({
    name: "",
    category_id: "",
    sub_category_id: "",
    template_id: "",
    sender_email: "",
    smtpServer: "",
    domain_id: "",
    errors_list: [],
  });
  const [categories, setCategories] = useState([]);
  const [subCategories, setSubCategories] = useState([]);
  const [templates, setTemplates] = useState([]);
  const [senderEmail, setSenderEmail] = useState([]);
  const [servers, setServers] = useState([]);
  const [domains, setDomains] = useState([]);
  const [totalLeads, setTotalLeads] = useState(0);

  useEffect(() => {
    axios.get(`/api/view-categories`).then((res) => {
      if (res.data.status === 200) {
        setCategories(res.data.categoryData);
      }
    });

    axios.get(`/api/get-from-email`).then((res) => {
      if (res.data.status === 200) {
        setSenderEmail(res.data.fromEmail);
      }
    });

    axios.get(`/api/get-templates`).then((res) => {
      if (res.data.status === 200) {
        setTemplates(res.data.templates);
      }
    });

    axios.get(`/api/get-servers`).then((res) => {
      if (res.data.status === 200) {
        setServers(res.data.serverData);
      }
    });

    // axios.get(`/api/get-domains`).then((res) => {
    //   if (res.data.status === 200) {
    //     setDomains(res.data.domains);
    //   }
    // });
  }, []);

  const handleInput = (e) => {
    if (e.target.name === "category_id") {
      const id = e.target.value;
      axios.get(`/api/get-subcategory/${id}`).then((res) => {
        if (res.data.status === 200) {
          setSubCategories(res.data.subcategoryData);
          setTotalLeads(res.data.totalLeads);
          console.warn("subcategory data", res.data.subcategoryData);
        } else if (res.data.status === 404) {
          console.warn("else part");
          // navigate("/admin");
        }
      });
    } else if (e.target.name === "smtpServer") {
      const id = e.target.value;
      axios.get(`/api/get-domains-by-server/${id}`).then((res) => {
        if (res.data.status === 200) {
          setDomains(res.data.domainData);
          console.warn("domains data", res.data.domainData);
        } else if (res.data.status === 404) {
          console.warn("else part");
          // navigate("/admin");
        }
      });
    }
    setCompagion({
      ...compagion,
      [e.target.name]: [e.target.value],
    });
  };

  const submitForm = (e) => {
    e.preventDefault();
    let form = new FormData();

    form.append("name", compagion.name);
    form.append("category_id", compagion.category_id);
    form.append("sub_category_id", compagion.sub_category_id);
    form.append("sender_email", compagion.sender_email);
    form.append("smtpServer", compagion.smtpServer);
    form.append("template_id", compagion.template_id);
    form.append("domain_id", compagion.domain_id);

    axios.post(`/api/add-compagion`, form).then((res) => {
      if (res.data.status === 200) {
        let data = res.data.cData;
        navigate(`/admin/send-compagion/${data}`);
        console.log("success", res.data.cData);
        notify("success", res.data.message);
      } else if (res.data.status === 403) {
        console.log("errors", res.data.errors);
        setCompagion({
          ...compagion,
          errors_list: res.data.errors,
        });
        notify("error", res.data.message);
      }
    });
  };
  return (
    <>
      <div className="container mt-4">
        <div className="card">
          <div className="card-header">
            <h4>
              Send Compagion
              <p className="btn btn-primary btn-sm float-end text-white">
                Total Leads: {totalLeads}
              </p>
              {/* <Link
                to="/admin/view-domain"
                className="btn btn-primary btn-sm float-end"
              >
                <i className="fa fa-eye" aria-hidden="true"></i>
                View Domains
              </Link> */}
            </h4>
          </div>

          <div className="card-body">
            <form onSubmit={submitForm}>
              <div className="row mb-4">
                <div className="col-sm-12 col-offset-1">
                  <div className="form-outline">
                    <label className="form-label" htmlFor="name">
                      Compagion Name
                    </label>
                    <input
                      type="text"
                      id="name"
                      className="form-control"
                      name="name"
                      value={compagion.name}
                      onChange={handleInput}
                    />
                    <span className="text-danger">
                      {compagion.errors_list.name}
                    </span>
                  </div>
                </div>
              </div>
              <div className="row mb-4">
                <div className="col-sm-4 col-offset-1">
                  <div className="form-outline">
                    <label className="form-label" htmlFor="category_id">
                      Category
                    </label>
                    <select
                      id="category_id"
                      className="form-control"
                      name="category_id"
                      value={compagion.category_id}
                      onChange={handleInput}
                    >
                      {categories?.map((category, id) => {
                        return (
                          <option key={id} value={category.id}>
                            {category.name}
                          </option>
                        );
                      })}
                    </select>

                    <span className="text-danger">
                      {compagion.errors_list.category_id}
                    </span>
                  </div>
                </div>
                <div className="col-sm-4 col-offset-1">
                  <div className="form-outline">
                    <label className="form-label" htmlFor="sub_category_id">
                      Sub Category
                    </label>
                    <select
                      id="sub_category_id"
                      className="form-control"
                      name="sub_category_id"
                      value={compagion.sub_category_id}
                      onChange={handleInput}
                    >
                      {subCategories?.map((subcategory, id) => {
                        return (
                          <option key={id} value={subcategory.id}>
                            {subcategory.name}
                          </option>
                        );
                      })}
                    </select>

                    <span className="text-danger">
                      {compagion.errors_list.sub_category_id}
                    </span>
                  </div>
                </div>
                <div className="col-sm-4 col-offset-1">
                  <div className="form-outline">
                    <label className="form-label" htmlFor="smtpServer">
                      SMTP Server
                    </label>
                    <select
                      id="smtpServer"
                      className="form-control"
                      name="smtpServer"
                      value={compagion.smtpServer}
                      onChange={handleInput}
                    >
                      {servers?.map((server, id) => {
                        return (
                          <option key={id} value={server.id}>
                            {server.host}
                          </option>
                        );
                      })}
                    </select>

                    <span className="text-danger">
                      {compagion.errors_list.category_id}
                    </span>
                  </div>
                </div>
              </div>
              <div className="row mb-4">
                <div className="col-sm-4 col-offset-1">
                  <div className="form-outline">
                    <label className="form-label" htmlFor="domain_id">
                      Domain
                    </label>
                    <select
                      id="domain_id"
                      className="form-control"
                      name="domain_id"
                      value={compagion.domain_id}
                      onChange={handleInput}
                    >
                      {domains?.map((domain, id) => {
                        return (
                          <option key={id} value={domain.id}>
                            {domain.name}
                          </option>
                        );
                      })}
                    </select>

                    <span className="text-danger">
                      {compagion.errors_list.domain_id}
                    </span>
                  </div>
                </div>

                <div className="col-sm-4 col-offset-1">
                  <div className="form-outline">
                    <label className="form-label" htmlFor="sender_email">
                      ReplyTo Email
                    </label>
                    <select
                      id="sender_email"
                      className="form-control"
                      name="sender_email"
                      value={compagion.sender_email}
                      onChange={handleInput}
                    >
                      {senderEmail?.map((sender, id) => {
                        return (
                          <option key={id} value={sender.id}>
                            {sender.email}
                          </option>
                        );
                      })}
                    </select>

                    <span className="text-danger">
                      {compagion.errors_list.sender_email}
                    </span>
                  </div>
                </div>
                <div className="col-sm-4 col-offset-1">
                  <div className="form-outline">
                    <label className="form-label" htmlFor="template_id">
                      Template
                    </label>
                    <select
                      id="template_id"
                      className="form-control"
                      name="template_id"
                      value={compagion.template_id}
                      onChange={handleInput}
                    >
                      {templates?.map((template, id) => {
                        return (
                          <option key={id} value={template.id}>
                            {template.name}
                          </option>
                        );
                      })}
                    </select>

                    <span className="text-danger">
                      {compagion.errors_list.template_id}
                    </span>
                  </div>
                </div>
              </div>

              <button type="submit" className="btn btn-primary btn-block mb-4">
                Create Compagion
              </button>
            </form>
          </div>
        </div>
      </div>
      <ToastContainer />
    </>
  );
};

export default SendCompagion;

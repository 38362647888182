import React, { useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { ToastContainer } from "react-toastify";
import { notify } from "../toastify/TostifyMessages";
import JoditEditor from "jodit-react";

const AddContent = () => {
  const [content, setContent] = useState({
    title: "",
    subject: "",

    errors_list: [],
  });
  const [isChecked, setIsChecked] = useState(false);

  const [contents, setContents] = useState("");

  const handleChanged = () => {
    setIsChecked(!isChecked);
  };
  const handleInput = (e) => {
    // console.log("check name", e.target.name);

    setContent({
      ...content,
      [e.target.name]: [e.target.value],
    });
  };

  const submitForm = (e) => {
    e.preventDefault();
    console.log("editor text", contents);
    const form = new FormData();

    form.append("title", content.title);
    form.append("subject", content.subject);
    form.append("message", contents);
    form.append("enable", isChecked);

    console.log("ischecked", isChecked);

    axios.post(`/api/add-email-content`, form).then((res) => {
      if (res.data.status === 200) {
        console.log("success", res.data.message);
        notify("success", res.data.message);
      } else if (res.data.status === 403) {
        console.log("errors", res.data.errors);
        setContent({
          ...content,
          errors_list: res.data.errors,
        });
        notify("error", res.data.message);
      }
    });
  };
  return (
    <>
      <div className="container mt-4">
        <div className="card">
          <div className="card-header">
            <h4>
              Add Email Content
              <Link
                to="/admin/email-content"
                className="btn btn-primary btn-sm float-end"
              >
                <i className="fa fa-eye" aria-hidden="true"></i>
                View Email Content
              </Link>
            </h4>
          </div>

          <div className="card-body">
            <form onSubmit={submitForm}>
              <div className="row mb-4">
                <div className="col-sm-6 col-offset-1">
                  <div className="form-outline">
                    <label className="form-label" htmlFor="form3Example1">
                      Email Title
                    </label>
                    <input
                      type="text"
                      id="form3Example1"
                      className="form-control"
                      name="title"
                      value={content.title}
                      onChange={handleInput}
                    />
                    <span className="text-danger">
                      {content.errors_list.title}
                    </span>
                  </div>
                </div>
                <div className="col-sm-6 col-offset-1">
                  <div className="form-outline">
                    <label className="form-label" htmlFor="form3Example1">
                      Email Subject
                    </label>
                    <input
                      type="text"
                      id="form3Example1"
                      className="form-control"
                      name="subject"
                      value={content.subject}
                      onChange={handleInput}
                    />
                    <span className="text-danger">
                      {content.errors_list.subject}
                    </span>
                  </div>
                </div>
              </div>
              <div className="row mb-4">
                <div className="col-sm-12 col-offset-1">
                  <div className="form-outline">
                    <label className="form-label" htmlFor="form3Example1">
                      Email Message
                    </label>
                    <JoditEditor
                      value={content.message}
                      onChange={(newContent) => setContents(newContent)}
                      style={{ height: "500px" }}
                    />
                    {/* <textarea
                      cols="10"
                      rows="8"
                      className="form-control"
                      type="text"
                      name="message"
                      value={content.message}
                      onChange={handleInput}
                    ></textarea> */}

                    <span className="text-danger">
                      {content.errors_list.message}
                    </span>
                  </div>
                </div>
              </div>
              <div className="row mb-4">
                <div className="col-sm-6 col-offset-1">
                  <div className="form-outline">
                    <label htmlFor="flexSwitchCheckChecked">Enable</label>
                    <input
                      className="mx-1"
                      type="checkbox"
                      name="messageEnable"
                      value={isChecked}
                      checked={isChecked}
                      onChange={handleChanged}
                    />
                  </div>
                </div>
              </div>

              <button type="submit" className="btn btn-primary btn-block mb-4">
                Add
              </button>
            </form>
          </div>
        </div>
      </div>
      <ToastContainer />
    </>
  );
};

export default AddContent;
